import React, { useEffect } from "react";
import { InputCheck } from "../../../controls";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";

const SalaryAccess = ({ setFeaturesAccess, featuresAccess }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!featuresAccess.id) {
      featuresAccess.isHaveSalaryPaymentAccess
        ? setFeaturesAccess((j) => ({
            ...j,
            salary: {
              isHavePaymentAccess: true,
              isHaveExportAccess: true,
              isHaveDeleteAccess: true,
            },
          }))
        : setFeaturesAccess((j) => ({
            ...j,
            salary: {
              isHavePaymentAccess: false,
              isHaveExportAccess: false,
              isHaveDeleteAccess: false,
            },
          }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresAccess.isHaveSalaryPaymentAccess]);

  const handleInpChg = (e) => {
    const { name, checked } = e.target;
    setFeaturesAccess((j) => ({
      ...j,
      salary: { ...j.salary, [name]: checked },
    }));
  };

  return (
    <Collapse in={featuresAccess.isHaveSalaryPaymentAccess || false}>
      <div className="ml-25 d-grid g-5 mt-5">
        <InputCheck
          label={t("isHavePaymentAccess")}
          checked={
            (featuresAccess.salary &&
              featuresAccess.salary.isHavePaymentAccess) ||
            false
          }
          name={"isHavePaymentAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveExportAccess")}
          checked={
            (featuresAccess.salary &&
              featuresAccess.salary.isHaveExportAccess) ||
            false
          }
          name={"isHaveExportAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveDeleteAccess")}
          checked={
            (featuresAccess.salary &&
              featuresAccess.salary.isHaveDeleteAccess) ||
            false
          }
          name={"isHaveDeleteAccess"}
          onClick={handleInpChg}
        />
      </div>
    </Collapse>
  );
};

export default SalaryAccess;
