import { Grid } from "@mui/material";
import React from "react";
import { Input, Select } from "../../controls";
import { DropLi } from "../../hooks";
import { MaxLength } from "../../hooks/Method";

const SalaryFields = ({ t, values, errors, handleInpChg }) => {
  const { Periodic } = DropLi();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Select
          label={t("salaryType")}
          name="salaryType"
          value={values.salaryType}
          error={errors.salaryType}
          onChange={handleInpChg}
          fullWidth
          options={Periodic}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label={t("salary")}
          name="salary"
          type="number"
          className="rmv-arrow"
          value={values.salary}
          error={errors.salary}
          onInput={(e) => (e.target.value = MaxLength(e.target.value, 9))}
          onChange={handleInpChg}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default SalaryFields;
