import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Autocomplete,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function InputAuto(props) {
  const {
    name,
    label,
    value,
    variant,
    onChange,
    options = [],
    error = null,
    required,
    placeholder,
    ...other
  } = props;
  const [val, setVal] = useState(null);

  useEffect(() => {
    setVal(options.find((j) => j.id === value));
  }, [options, value]);

  const handleChange = (even, newVal) => {
    setVal(newVal);
    var e = {
      target: {
        value: newVal ? newVal.id : "",
        name: name,
      },
    };
    onChange(e);
  };

  const handleInpChg = (even, newVal) => {
    if (!newVal) {
      setVal(null);
      handleChange(null, newVal);
    }
  };

  return (
    <Autocomplete
      disableClearable
      autoHighlight
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      options={options}
      value={val || null}
      onChange={handleChange}
      onInputChange={handleInpChg}
      sx={{
        "& .MuiFilledInput-root::before": {
          borderBottom: `1px solid var(--grayMD-color)`,
        },
      }}
      {...other}
      renderOption={(prop, opt, i) => (
        <ListItemButton
          {...prop}
          key={opt.id + i + opt.label}
          sx={{
            padding: "0px 15px 0px 15px !important",
            mb: "5px",
            color: "var(--gray-color)",
          }}
        >
          <ListItemText primary={opt.label} secondary={opt.subtitle} />
        </ListItemButton>
      )}
      componentsProps={{
        paper: {
          sx: {
            backgroundColor: "var(--white-color)",
          },
        },
      }}
      popupIcon={<KeyboardArrowDownRounded />}
      renderInput={(params) => (
        <TextField
          variant={variant || "outlined"}
          label={label}
          required={required}
          placeholder={placeholder}
          {...params}
          {...(error && { error: true, helperText: error })}
        />
      )}
    />
  );
}
