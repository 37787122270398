import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonGroup, Input } from "../../../controls";
import { DropLi } from "../../../hooks";

const AdditionalDiscount = ({ values, setValues, handleInpChg, totalPay }) => {
  const { t } = useTranslation();
  const { DiscountTypes } = DropLi();

  const handleDisType = (val) => {
    setValues((x) => ({ ...x, discountType: val, discount: "" }));
  };

  const handleChange = (e) => {
    var val = parseFloat(e.target.value);
    if (
      val <= totalPay ||
      values.discount.length > e.target.value.length ||
      (values.discountType === 1 && val <= 100)
    )
      handleInpChg(e);
  };

  return (
    <>
      <Grid item xs={12} md={5} className="d-flex a-center">
        <span className="fs-16 fw-5 tx-upp gray-color">
          {t("additionalDiscount")}
        </span>
      </Grid>
      <Grid item xs={12} md={7} className="mb-10 d-flex">
        <Input
          fullWidth
          placeholder={t("enterAmount")}
          name="discount"
          value={values.discount}
          onInput={(e) => (e.target.value > 0 ? e : (e.target.value = ""))}
          onChange={handleChange}
          className="rmv-arrow br-8"
          variant="outlined"
          size="small"
          onClick={(e) => e.target.select()}
        />
        <ButtonGroup
          activeVal={values.discountType || 0}
          onChange={handleDisType}
          list={DiscountTypes}
          size="small"
        />
      </Grid>
    </>
  );
};

export default AdditionalDiscount;
