import { ClearRounded, VisibilityRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { controller, endpoints } from "../../api/actions/api";
import { Alert, Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { useContext } from "react";
import { AppContext } from "../../App";
import ViewDetails from "./ViewDetails";
import { IsEmptyObj } from "../../hooks/Method";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

const Inventory = (props) => {
  const { _getById, _delete } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoader, selStore, org, setPageTitle, authUser } =
    useContext(AppContext);
  const [viewData, setViewData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [access, setAccess] = useState({});
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (
      authUser &&
      authUser.featuresAccess &&
      authUser.featuresAccess.inventory
    )
      setAccess({ ...authUser.featuresAccess.inventory });
    return () => {};
  }, [authUser]);

  useEffect(() => {
    setPageTitle(t("inventories"));
    if (selStore) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setTblData(
            res.result.map((obj) => ({
              ...obj,
              name: obj.product.name,
              barcode: obj.product.barcode,
              addedUser:
                obj.user && obj.user.firstName + " " + obj.user.lastName,
              category:
                obj.product.category !== null ? obj.product.category.name : "",
              subCat:
                obj.product.parentCat !== null
                  ? obj.product.parentCat.name
                  : "",
              invVal:
                (org && org.curSymbol) +
                " " +
                parseFloat(obj.product.sellPrice * obj.inInventory).toFixed(
                  org.decimal
                ),
            }))
          );
      };

      _getById(
        endpoints.Inventory + controller.GetProdsByStore,
        selStore,
        null,
        onComplete
      );
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleAddEdit = (val) => {
    navigate(`inventory-form/${val.id}`);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("dltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
            setTblData((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else
            setAlert({
              isOpen: true,
              type: "success",
              title: t("warnning"),
              subTitle: res.msg,
            });
        };
        _delete(
          endpoints.Inventory,
          id,
          actions.ActionType.RmvList,
          onComplete
        );
      },
    });
  };

  const handleView = (data) => {
    setViewData(data);
    setShowForm(!showForm);
  };

  useEffect(() => {
    setCsvHeader([
      { key: "id", label: t("id") },
      { key: "name", label: t("name") },
      { key: "barcode", label: t("barcode") },
      { key: "category", label: t("category") },
      { key: "subcategory", label: t("subcategory") },
      { key: "inInventory", label: t("inInventory") },
      { key: "invVal", label: t("inventoryValue") },
    ]);
    return () => {
      setCsvHeader();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ActionComp = (props) => (
    <Box>
      {access.isHaveViewAccess && (
        <Tooltip arrow title={t("view")}>
          <IconButton
            onClick={() => handleView(props)}
            size="small"
            className="btn-primary mr-5"
          >
            <VisibilityRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
      {access.isHaveDeleteAccess && (
        <Tooltip arrow title={t("delete")}>
          <IconButton
            onClick={() => handleDlt(props.id)}
            size="small"
            className="error-color"
          >
            <ClearRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      {access.isHaveAddAccess && (
        <Button
          onClick={handleAddEdit}
          className="btn-secondary"
          sx={{
            width: { md: "180px", xs: "100%" },
            mb: { xs: "10px", md: "0px" },
            fontSize: "17px",
            padding: "8px",
          }}
        >
          {t("addInventory")}
        </Button>
      )}
      {access.isHaveExportAccess && (
        <Box
          className="d-flex"
          sx={{
            width: { xs: "100%", md: "auto" },
          }}
        >
          <CSVLink
            data={tblData}
            headers={csvHeader}
            filename={`${t("inventory")} (${new Date().toDateString()}).csv`}
            target="_blank"
            className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
          >
            {t("exportCSV")}
          </CSVLink>
        </Box>
      )}
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "name", label: t("name") },
          { id: "barcode", label: t("barcode") },
          { id: "category", label: t("category") },
          { id: "subCat", label: t("subcategory") },
          { id: "inInventory", label: t("inInventory") },
          { id: "invVal", label: t("inventoryValue") },
        ]}
        tblData={tblData}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
      {/* Edit Dialog */}
      <Dialog show={showForm} maxWidth="lg" onClose={false}>
        {!IsEmptyObj(viewData) && (
          <ViewDetails {...{ setShowForm, viewData }} />
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _defStore: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(Inventory);
