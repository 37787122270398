import React, { useState } from "react";
import { Model } from "../../../hooks";
import ChangePass from "./ChangePass";
import SendFrgtCode from "./SendFrgtCode";
import VerifyFrgtCode from "./VerifyFrgtCode";
import { Paper } from "@mui/material";

const Forgot = (props) => {
  document.title = "Forgot Password";
  const { ForgotMdl } = Model();
  const [forgotVal, setForgotVal] = useState(ForgotMdl());
  const [activeStep, setActiveStep] = useState();

  const Content = (step) => {
    switch (step) {
      case 1:
        return (
          <VerifyFrgtCode
            {...{
              setActiveStep,
              forgotVal,
              setForgotVal,
            }}
          />
        );
      case 2:
        return (
          <ChangePass
            {...{
              setActiveStep,
              forgotVal,
              setForgotVal,
            }}
          />
        );
      default:
        return (
          <SendFrgtCode
            {...{
              setActiveStep,
              forgotVal,
              setForgotVal,
            }}
          />
        );
    }
  };

  return (
    <div className="h-100vh d-grid a-center j-center">
      <Paper className="bs-primaryMD" sx={{ maxWidth: 380 }}>
        {Content(activeStep)}
      </Paper>
    </div>
  );
};

export default Forgot;
