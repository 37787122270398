import { ClearRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Alert, Table } from "../../../layout";
import * as actions from "../../../api/actions/pos";
import { DropLi } from "../../../hooks";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../../App";
import { endpoints } from "../../../api/actions/api";
import { useTranslation } from "react-i18next";
import { ChangeArrayKey } from "../../../hooks/Method";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";

const SalaryHistory = (props) => {
  const { _getById, _delete } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Periodic } = DropLi();
  const { setLoader, selStore, org, setPageTitle, authUser } =
    useContext(AppContext);
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [access, setAccess] = useState({});
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (authUser && authUser.featuresAccess && authUser.featuresAccess.salary)
      setAccess({ ...authUser.featuresAccess.salary });
    return () => {};
  }, [authUser]);

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("dltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
            setTblData((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
        };
        _delete(endpoints.Salary, id, actions.ActionType.RmvList, onComplete);
      },
    });
  };

  useEffect(() => {
    setPageTitle(t("salaryPayments"));
    if (selStore && org) {
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setTblData(
            res.result.map((obj) => {
              var salaryType =
                obj.user && obj.user.salaryType
                  ? Periodic.find((j) => j.id === obj.user.salaryType)
                  : "";
              return {
                ...obj,
                firstName: obj.user.firstName,
                lastName: obj.user.lastName,
                dob: obj.user.dob,
                salaryType: salaryType ? salaryType.label : "",
                salary:
                  obj.user.salary &&
                  `${org.curSymbol} ${obj.user.salary.toFixed(org.decimal)}`,
                totalSalary:
                  org.curSymbol + " " + obj.totalSalary.toFixed(org.decimal),
              };
            })
          );
      };

      setLoader(true);
      _getById(endpoints.Salary, selStore, null, onComplete);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    setCsvHeader([
      { key: "firstName", label: t("firstName") },
      { key: "lastName", label: t("lastName") },
      { key: "dob", label: t("dateOfBirth") },
      { key: "salaryType", label: t("salaryType") },
      { key: "salary", label: t("salary") },
      { key: "payDate", label: t("payPeriod") },
      { key: "totalHours", label: t("totalWorkHour") },
      { key: "totalSalary", label: t("totalSalary") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddEdit = () => {
    navigate("/pay_salary");
  };

  const HeaderComp = () => (
    <Box
      className="d-flex g-5"
      sx={{
        width: { xs: "100%", md: "auto" },
      }}
    >
      {access.isHavePaymentAccess && (
        <Button
          onClick={() => handleAddEdit()}
          className="btn-secondary"
          sx={{
            width: { md: "180px", xs: "100%" },
            mb: { xs: "10px", md: "0px" },
            fontSize: "17px",
            padding: "8px",
          }}
        >
          {t("payNow")}
        </Button>
      )}
      {access.isHaveExportAccess && (
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("productReport")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-10"
        >
          {t("exportCSV")}
        </CSVLink>
      )}
    </Box>
  );

  const ActionComp = (props) => (
    <Box>
      {access.isHaveDeleteAccess && (
        <Tooltip arrow title={t("delete")}>
          <IconButton
            onClick={() => handleDlt(props.id)}
            size="small"
            className="error-color"
          >
            <ClearRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          ...ChangeArrayKey(csvHeader, "key", "id"),
        ]}
        tblData={tblData}
      />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(SalaryHistory);
