import React, { useContext, useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
} from "@mui/material";
import { Button, Input, InputAuto, InputMultImg, Select } from "../../controls";
import { DropLi, Model, UseForm } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ViewWeekRounded } from "@mui/icons-material";
import {
  MaxLength,
  NestedFormData,
  RandomCode,
  RmvEmptyProp,
} from "../../hooks/Method";
import { Alert, Snackbar } from "../../layout";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import InputSellPrice from "./SellPrice/InputSellPrice";
import { action, Server, controller } from "../../api/Server";

const AddEditProd = (props) => {
  const { setShowPurchForm } = props;
  const { t } = useTranslation();
  const { ProdMdl } = Model();
  const { id } = useParams();
  const { selStore, setPageTitle, authUser } = useContext(AppContext);
  const { UnitType, BoolType } = DropLi();
  const navigate = useNavigate();
  const [cats, setCats] = useState([]);
  const [snackbar, setSnackbar] = useState({
    type: "success",
    msg: "",
    show: false,
  });
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleFile,
    resetFormControls,
  } = UseForm(ProdMdl);
  const [mainCats, setMainCats] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [imgSrc, setImgSrc] = useState([]);

  useEffect(() => {
    setPageTitle((id ? t("update") : t("add")) + (" " + t("product")));
    if (
      authUser &&
      authUser.featuresAccess &&
      authUser.featuresAccess.product
    ) {
      var _access = authUser.featuresAccess.product;
      !_access.isHaveAddAccess &&
        !_access.isHaveEditAccess &&
        navigate("/dashboard");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    // Get Categories
    if (authUser && authUser.id) {
      Server(controller.Category).get((res) => {
        res.status === 200 && setCats([...res.result]);
        setLoader(false);
      });
    }

    // Get product details.
    id &&
      Server(controller.Product + action.GetById).getById(id, (res) => {
        if (res && res.status === 200) {
          var val = RmvEmptyProp(res.result);
          setValues((x) => ({
            ...x,
            ...val,
            sellPrices: val.sellPrices.map((price) => ({
              ...price,
              key: RandomCode(15),
            })),
          }));
          setImgSrc(val.imgs.map((j) => ({ ...j, src: j.imgUrl })));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setMainCats([
      ...cats
        .filter((j) => !j.parentCatId)
        .map((j) => ({ ...j, label: j.name })),
    ]);
    return () => {};
  }, [cats]);

  // Set Subcategory
  useEffect(() => {
    setSubCat([
      ...cats
        .filter((j) => j.parentCatId === values.categoryId)
        .map((j) => ({ ...j, label: j.name })),
    ]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.categoryId]);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : t("nameIsRequired");
    temp.barcode = values.barcode !== "" ? "" : t("barcodeIsRequired");
    temp.unit = values.unit !== "" ? "" : t("unitTypeIsTequired");
    temp.tax = values.tax !== "" ? "" : t("taxIsRequired");
    temp.purchPrice =
      values.purchPrice !== "" ? "" : t("purchasePriceIsIequired");
    temp.sellPrices =
      values.sellPrices && values.sellPrices.length > 0
        ? ""
        : t("sellPriceIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (isSaveExit = false) => {
    if (validateForm()) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        if (res.status === 201 || res.status === 200) {
          if (isSaveExit) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("success"),
              subTitle: res.msg,
              onClick: () => navigate("/products"),
            });
          } else {
            resetFormControls();
            setImgSrc([]);
            setSnackbar({
              type: "success",
              show: true,
              msg: t("prodAddTitle"),
            });
          }
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: res.msg,
          });
      };

      if (!id) {
        values.userId = authUser.id;
        values.storeId = selStore;
        Server(controller.Product).post(
          NestedFormData(RmvEmptyProp(values)),
          onComplete
        );
      } else if (id) {
        Server(controller.Product).put(
          NestedFormData(
            RmvEmptyProp({ ...values, updateDate: new Date().toISOString() })
          ),
          onComplete
        );
      }
    }
  };

  const handleGenerateCode = (name) => {
    setValues({
      ...values,
      [name]: Math.floor(10000000000 + Math.random() * 10000000000),
    });
    setErrors((j) => ({ ...j, [name]: "" }));
  };

  return (
    <>
      <Paper>
        <div className="mb-10 d-flex flex-wrap g-20 j-between a-center pt-10 pl-10 pr-10">
          <div>
            <span className="fs-20 fw-5 fw-4 gray-color tx-upp border-left">
              {id === undefined ? t("add") : t("update")} {t("product")}
            </span>
          </div>

          <div>
            {setShowPurchForm && (
              <Button
                loading={loader}
                onClick={() => setShowPurchForm(false)}
                variant="text"
                sx={{
                  width: { md: "150px", xs: "100%" },
                  mr: { md: "10px", xs: "0px" },
                  mb: { md: "0px", xs: "10px" },
                }}
              >
                {t("close")}
              </Button>
            )}
            {id === undefined && !setShowPurchForm && (
              <Button
                loading={loader}
                onClick={() => handleSubmit(true)}
                variant="text"
                sx={{
                  minWidth: { md: "150px", xs: "100%" },
                  mr: { md: "10px", xs: "0px" },
                  mb: { xs: "10px", md: "0px" },
                }}
                className="pr-10 pl-10"
              >
                {t("saveAndExit")}
              </Button>
            )}
            <Button
              loading={loader}
              onClick={() => handleSubmit(id === undefined ? false : true)}
              sx={{ minWidth: { md: "230px", xs: "100%" } }}
              variant="text"
            >
              {id === undefined ? t("saveAndAddAnother") : t("saveChanges")}
            </Button>
          </div>
        </div>
        <Divider />
        <Grid container spacing={3} className="p-20 pt-0">
          <Grid item xs={12} md={4}>
            <Input
              label={t("barcode")}
              name="barcode"
              value={values.barcode}
              error={errors.barcode}
              onChange={handleInpChg}
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip arrow title={t("generateBarcode")}>
                      <IconButton onClick={() => handleGenerateCode("barcode")}>
                        <ViewWeekRounded />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Input
              label={t("productName")}
              name="name"
              value={values.name}
              error={errors.name}
              onChange={handleInpChg}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputAuto
              label={t("category")}
              name="categoryId"
              fullWidth
              value={values.categoryId}
              error={errors.categoryId}
              onChange={handleInpChg}
              options={mainCats}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputAuto
              fullWidth
              label={t("subcategory")}
              name="parentCatId"
              value={values.parentCatId}
              error={errors.parentCatId}
              onChange={handleInpChg}
              options={subCat}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              label={t("unitType")}
              name="unit"
              fullWidth
              value={values.unit}
              error={errors.unit}
              onChange={handleInpChg}
              options={UnitType}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              label={t("tax")}
              name="tax"
              fullWidth
              value={values.tax}
              error={errors.tax}
              onChange={handleInpChg}
              options={BoolType}
              required
            />
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <Input
              label={t("alertQty")}
              type="number"
              name="alertQty"
              onInput={(e) => (e.target.value = MaxLength(e.target.value, 10))}
              value={values.alertQty}
              error={errors.alertQty}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} md={3}>
            <Input
              label={t("purchasePrice")}
              onInput={(e) => (e.target.value = MaxLength(e.target.value, 12))}
              name="purchPrice"
              value={values.purchPrice}
              error={errors.purchPrice}
              onChange={handleInpChg}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputSellPrice
              {...{ setValues, values, error: errors.sellPrices, setErrors }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={t("productDescription")}
              multiline
              rows={4}
              name="descr"
              value={values.descr}
              error={errors.descr}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <div className="d-flex flex-wrap g-10">
              <InputMultImg
                label={t("productImages")}
                name="imgsFile"
                maxUpl={5}
                src={imgSrc}
                values={values.imgsFile}
                onChange={handleFile}
                rmv={(file) => {
                  setValues((x) => ({
                    ...x,
                    imgsFile: x.imgsFile.filter(
                      (j) => j.lastModified !== file.lastModified
                    ),
                    imgs: x.imgs.filter((j) => j.id !== file.id),
                  }));
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Snackbar {...{ snackbar, setSnackbar }} />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default AddEditProd;
