import {
  ClearRounded,
  EditRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Dialog, Table } from "../../../layout";
import * as actions from "../../../api/actions/pos";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import { IsEmptyObj } from "../../../hooks/Method";
import ViewReturnItems from "./ViewReturnItems";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

const PurchReturn = (props) => {
  const { _getById, _delete } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoader, selStore, org, authUser, setPageTitle } =
    useContext(AppContext);
  const [showForm, setShowForm] = useState(false);
  const [viewData, setViewData] = useState({});
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [access, setAccess] = useState({});
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setPageTitle && setPageTitle(t("purchasesReturn"));
    if (
      authUser &&
      authUser.featuresAccess &&
      authUser.featuresAccess.purchaseReturn
    )
      setAccess({ ...authUser.featuresAccess.purchaseReturn });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    if (selStore && org) {
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setTblData([
            ...res.result.map((obj) => ({
              ...obj,
              supplier: obj.purchase.supplier && obj.purchase.supplier.company,
              reference: obj.purchase.id,
              totalItem: obj.purchReturnItems
                .map((j) => j.qty)
                .reduce((x, y) => x + y, 0),
              returnValue:
                (org && org.curSymbol) +
                " " +
                obj.purchReturnItems
                  .map((j) => j.qty * j.product.purchPrice)
                  .reduce((x, y) => x + y, 0)
                  .toFixed(org.decimal),
              link: obj.purchase.recept,
              viewRecept: (
                <a href={obj.purchase.recept} target="_blank" rel="noreferrer">
                  {obj.purchase.recept && t("viewRecept")}
                </a>
              ),
            })),
          ]);
      };

      setLoader(true);
      _getById(
        endpoints.PurchReturn + controller.GetByStore,
        selStore,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore, org]);

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("dltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
            setTblData((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
        };
        _delete(endpoints.PurchReturn, id, null, onComplete);
      },
    });
  };

  const handleView = (data) => {
    setViewData(data);
    setShowForm(!showForm);
  };

  useEffect(() => {
    setCsvHeader([
      { key: "reference", label: t("referenceNo") },
      { key: "supplier", label: t("supplier") },
      { key: "totalItem", label: t("totalItem") },
      { key: "returnValue", label: t("returnValue") },
      { key: "returnDate", label: t("totalPurchase") },
      { key: "link", label: t("recept") },
    ]);

    return () => {
      setCsvHeader();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ActionComp = (props) => (
    <Box>
      {access.isHaveViewAccess && (
        <Tooltip arrow title={t("view")}>
          <IconButton
            onClick={() => handleView(props)}
            size="small"
            className="btn-primary mr-5"
          >
            <VisibilityRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
      {access.isHaveEditAccess && (
        <Tooltip arrow title={t("Edit")}>
          <IconButton
            onClick={() => navigate(`purchase-return-form/${props.id}`)}
            size="small"
            className="btn-primary mr-5"
          >
            <EditRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
      {access.isHaveDeleteAccess && (
        <Tooltip arrow title={t("Delete")}>
          <IconButton
            onClick={() => handleDlt(props.id)}
            size="small"
            color="error"
          >
            <ClearRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      {access.isHaveReturnAccess && (
        <Button
          onClick={() => navigate("purchase-return-form")}
          className="btn-secondary"
          sx={{
            width: { md: "250px", xs: "100%" },
            mb: { xs: "10px", md: "0px" },
            fontSize: "17px",
            padding: "8px",
          }}
        >
          {t("addPurchaseReturn")}
        </Button>
      )}
      {access.isHaveExportAccess && (
        <Box
          className="d-flex"
          sx={{
            width: { xs: "100%", md: "auto" },
          }}
        >
          <CSVLink
            data={tblData}
            headers={csvHeader}
            filename={`${t(
              "purchaseReturn"
            )} (${new Date().toDateString()}).csv`}
            target="_blank"
            className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
          >
            {t("exportCSV")}
          </CSVLink>
        </Box>
      )}
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "reference", label: t("referenceNo") },
          { id: "supplier", label: t("supplier") },
          { id: "totalItem", label: t("totalItem") },
          { id: "returnValue", label: t("returnValue") },
          { id: "returnDate", label: t("returnDate") },
          ...(access.isHaveViewReceiptAccess
            ? [{ id: "viewRecept", label: t("recept") }]
            : []),
        ]}
        tblData={tblData}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
      {/* Edit Dialog */}
      <Dialog show={showForm} maxWidth="lg" onClose={false}>
        {!IsEmptyObj(viewData) && (
          <ViewReturnItems {...{ setShowForm, viewData }} />
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(PurchReturn);
