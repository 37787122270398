import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Input, InputPass, MultiSelect, Select } from "../../controls";
import { DropLi } from "../../hooks";
import { PassValidation } from "../../utils";
import { SecurityRounded } from "@mui/icons-material";
import { Dialog } from "../../layout";
import FeaturesPermissionForm from "./permissionForm/FeaturesPermissionForm";
import { AppContext } from "../../App";

const PermissionsFields = ({
  t,
  values,
  setValues,
  errors,
  handleInpChg,
  handleInpPass,
  storeLi,
  passValid,
  checkUserName,
}) => {
  const { authUser } = useContext(AppContext);
  const { Role } = DropLi();
  const [showPermissionForm, setShowPermissionForm] = useState(false);

  useEffect(() => {
    if (values.id === 0) {
      values.role === 1
        ? setValues((j) => ({
            ...j,
            stores: [...storeLi.map((store) => store.id)],
          }))
        : setValues((j) => ({ ...j, stores: [] }));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.role]);

  const handlePermissionForm = () => {
    setShowPermissionForm(!showPermissionForm);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-10">
          <div className="d-flex a-center j-between">
            <span className="border-left tx-upp fw-5 gray-color pl-10 ml-10">
              {t("rolesAndPermissions")}
            </span>
            {authUser.role !== 5 && (
              <Button
                onClick={handlePermissionForm}
                variant="text"
                startIcon={<SecurityRounded />}
              >
                Set Permissions
              </Button>
            )}
          </div>
        </Grid>
        {authUser.role !== 5 && (
          <Grid item xs={12} md={4}>
            <Select
              label={t("role")}
              name="role"
              value={values.role}
              error={errors.role}
              onChange={handleInpChg}
              fullWidth
              options={Role}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <MultiSelect
            label={t("store")}
            name="stores"
            value={values.stores}
            error={errors.stores}
            onChange={handleInpChg}
            fullWidth
            options={storeLi || []}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            label={t("userName")}
            name="userName"
            value={values.userName}
            error={errors.userName}
            autoComplete="off"
            onChange={handleInpChg}
            onBlur={(e) => checkUserName(e.target.value)}
            fullWidth
            inputProps={{ maxLength: 400 }}
          />
        </Grid>
        {values.role !== 4 && (
          <>
            <Grid item xs={12} md={6}>
              <InputPass
                label={t("password")}
                name="password"
                value={values.password}
                onChange={handleInpPass}
                error={errors.password}
                className="input-primary w-100pr"
              />
              <PassValidation {...{ password: values.password, passValid }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputPass
                label={t("confirmPassword")}
                name="confirmPass"
                value={values.confirmPass}
                onChange={(e) => handleInpPass(e, true)}
                error={
                  errors.confirmPass !== "" &&
                  values.password !== "" &&
                  !passValid.match &&
                  values.confirmPass !== ""
                    ? t("passwordAndConfirmPasswordNotMatch")
                    : errors.confirmPass
                }
                className="input-primary w-100pr"
              />
            </Grid>
          </>
        )}
      </Grid>

      {/* Permission Form */}
      {showPermissionForm && (
        <Dialog show={showPermissionForm} onClose={false} maxWidth="lg">
          <FeaturesPermissionForm
            {...{
              setShowForm: setShowPermissionForm,
              setValues,
              values,
            }}
          />
        </Dialog>
      )}
    </>
  );
};

export default PermissionsFields;
