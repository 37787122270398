import { Divider } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { InputCheck } from "../../../../controls";

const AmountToPay = ({ needToPayAmt, org, values, handleInpCheck }) => {
  const { t } = useTranslation();

  const renderContent = () => {
    if (values) {
      if (parseFloat(values.change || 0) <= 0) {
        return (
          <>
            <span className="">{t("amountToPay")}: &nbsp;&nbsp;</span>
            <span className="fs-24 fw-6">
              {org &&
                org.curSymbol +
                  " " +
                  parseFloat(needToPayAmt).toFixed(org.decimal)}
            </span>
          </>
        );
      } else {
        return (
          <>
            <div className="gray-color tx-upp d-flex a-center">
              <span className="fs-18">
                {t("change")}: &nbsp;&nbsp;
                {org.curSymbol}&nbsp;
              </span>
              <span className="fs-24 fw-6">
                {parseFloat(values.change || 0).toFixed(org.decimal)}
              </span>
            </div>
            {values.cstmrId && values.change > 0 && (
              <>
                <Divider />
                <InputCheck
                  label={t("keepChgAsCredit")}
                  size="small"
                  className="gray-color"
                  onChange={handleInpCheck}
                  name="changeAddedCredit"
                  checked={values.changeAddedCredit}
                />
              </>
            )}
          </>
        );
      }
    }
  };
  return (
    <>
      <div className="d-flex j-between a-center pt-10 pb-15 gray-color fs-24 fw-5 tx-upp">
        {renderContent()}
      </div>
      <Divider />
    </>
  );
};

export default AmountToPay;
