import {
  Autocomplete,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { Button, Input, LabelComp } from "../../controls";
import { Model, UseForm } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { MaxLength, ToFormData } from "../../hooks/Method";
import { useState } from "react";
import { useEffect } from "react";
import { Snackbar } from "../../layout";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const AddEditInventory = (props) => {
  const { _post, _getById, _getByObj } = props;
  const { t } = useTranslation();
  const { InventoryMdl } = Model();
  const navigate = useNavigate();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(InventoryMdl);
  const { selStore, setPageTitle, org } = useContext(AppContext);
  const [prod, setProd] = useState({});
  const [findErr, setFindErr] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [searchLi, setSearchLi] = useState([]);
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    type: "success",
    msg: "",
    show: false,
  });

  const validateForm = () => {
    let temp = {};
    temp.newInventory =
      values.newInventory !== "" ? "" : t("newStockIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (isSaveExit = false) => {
    const onComplete = () => {
      if (isSaveExit) {
        _getById(
          endpoints.Inventory + controller.GetProdsByStore,
          selStore,
          actions.ActionType.List,
          navigate("/inventory")
        );
      } else {
        resetFormControls();
        setProd({});
        setSnackbar((x) => ({
          ...x,
          show: true,
          msg: t("invAddedTitle"),
        }));
      }
      setLoading(false);
    };

    if (validateForm() && prod.id !== undefined) {
      setLoading(true);
      setErr("");
      _post(endpoints.Inventory, ToFormData(values), null, onComplete);
    } else if (prod.id === undefined) setErr(t("invProdError"));
  };

  useEffect(() => {
    setPageTitle && setPageTitle(t("addInventory"));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prod.id) {
      _getById(
        endpoints.Inventory + controller.GetById,
        prod.id,
        null,
        (res) =>
          res &&
          res.status === 200 &&
          setProd((j) => ({ ...j, inInventory: res.result.inInventory || 0 }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prod.id]);

  const GetDataFromServer = (val, onComplete) => {
    // Get Data From Server
    val &&
      _getByObj(
        endpoints.Product + controller.FindProducts,
        {
          storeId: selStore,
          value: val,
        },
        null,
        onComplete
      );
  };

  const handleSearch = (e, val) => {
    setSearchVal(val);
    const onComplete = (res) => {
      if (res.status === 200) {
        setSearchLi([
          ...res.result.map((j) => {
            return {
              ...j,
              id: j.id,
              label: `${j.name} (${j.barcode})`,
            };
          }),
        ]);
        setFindErr("");
      }
    };
    e && GetDataFromServer(e.target.value, onComplete);
  };

  const handleScan = (e) => {
    const onComplete = (res) => {
      const product = res.result[0];
      if (res.status === 200 && product) handleSelect(null, product);
      else setFindErr(t("productNotFound"));
    };
    GetDataFromServer(e.target.value, onComplete);
  };

  const handleSelect = (e, product) => {
    if (product && product.id) {
      setValues((j) => ({
        ...j,
        productId: product.id,
        storeId: product.storeId,
      }));
      setProd({ ...product, inInventory: 0 });
    }
  };

  return (
    <>
      <Paper className="mt-30 shadow-xs br-8">
        <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
          <div>
            <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
              {t("addInventory")}
            </h1>
          </div>

          <div>
            <Button
              loading={loading}
              onClick={() => handleSubmit(true)}
              variant="text"
              sx={{
                minWidth: { md: "150px", xs: "100%" },
                mr: { md: "10px", xs: "0px" },
                mb: { xs: "10px", md: "0px" },
              }}
              className="pr-10 pl-10"
            >
              {t("saveAndExit")}
            </Button>
            <Button
              loading={loading}
              onClick={() => handleSubmit(false)}
              variant="text"
              sx={{ minWidth: { md: "230px", xs: "100%" } }}
            >
              {t("saveAndAddAnother")}
            </Button>
          </div>
        </div>
        <Divider />
        <Grid container spacing={3} className="p-20 pt-0">
          {err && (
            <Grid item xs={12}>
              <Typography color="error">
                <span className="tx-upp fw-6">{t("error")} :</span> {err}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="searchBox"
              options={searchLi}
              onChange={handleSelect}
              onInputChange={handleSearch}
              isOptionEqualToValue={(opt, val) => opt.id === val.id}
              onKeyDown={(e) => e.which === 13 && handleScan(e, searchVal)}
              componentsProps={{
                paper: {
                  sx: {
                    backgroundColor: "var(--white-color)",
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  label={t("searchByNameOrBarcode")}
                  {...(findErr && { error: true, helperText: findErr })}
                  {...params}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label={t("newStock")}
              name="inInventory"
              className="rmv-arrow"
              value={values.inInventory}
              error={errors.inInventory}
              type="number"
              onInput={(e) => (e.target.value = MaxLength(e.target.value, 10))}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabelComp label={t("productId")} value={prod.id} />
          </Grid>
          <Grid item xs={12} md={10}>
            <LabelComp label={t("productName")} value={prod.name} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelComp label={t("inStock")} value={prod.inInventory} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelComp
              label={t("purchasePrice")}
              value={parseFloat(prod.purchPrice || 0).toFixed(org.decimal)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelComp
              label={t("sellPrice")}
              value={parseFloat(prod.sellPrice || 0).toFixed(org.decimal)}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelComp label={t("productDetails")} value={prod.descr} />
          </Grid>
        </Grid>
      </Paper>
      <Snackbar {...{ snackbar, setSnackbar }} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _data: state.pos.data,
  _type: state.pos.type,
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
  _getByObj: actions.getByObj,
  _post: actions.post,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditInventory);
