import { VisibilityRounded } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { endpoints } from "../../api/actions/api";
import ViewPurch from "../purchase/ViewPurch";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { ChangeArrayKey } from "../../hooks/Method";

const PurchaseReport = (props) => {
  const { _get } = props;
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const { setLoader, org, setPageTitle } = useContext(AppContext);
  const [purchases, setPurchases] = useState([]);
  const [viewData, setViewData] = useState();
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);

  const handleView = (data) => {
    setViewData(data);
    setShowForm(true);
  };

  const onComplete = (res) => {
    setLoader(false);
    res.status === 200 && res.result.length > 0 && setPurchases(res.result);
  };

  useEffect(() => {
    setLoader(true);
    _get(endpoints.Purchase, null, onComplete);
    return () => {
      setPurchases([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageTitle(t("purchaseReport"));

    setCsvHeader([
      { key: "purchDate", label: t("purchaseDate") },
      { key: "purchBy", label: t("purchaseBy") },
      { key: "totalItem", label: t("totalItem") },
      { key: "totalPrice", label: t("totalPrice") },
      { key: "supplier", label: t("supplierName") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTblData([
      ...purchases.map((purch) => ({
        ...purch,
        purchBy: purch.user.firstName + " " + purch.user.lastName,
        totalItem: purch.purchItems
          .map((j) => j.qty)
          .reduce((x, y) => x + y, 0),
        totalPrice:
          (org && org.curSymbol) +
          " " +
          purch.purchItems
            .map((j) => j.qty * j.purchPrice)
            .reduce((x, y) => x + y, 0)
            .toFixed(org.decimal),
        supplier: purch.supplier.company,
      })),
    ]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchases]);

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("View")}>
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          className="btn-primary mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={tblData}
          headers={csvHeader}
          filename={`${t("purchaseReport")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          ...ChangeArrayKey(csvHeader, "key", "id"),
        ]}
        tblData={tblData}
      />

      {/* Edit Dialog */}
      {viewData && (
        <Dialog show={showForm} onClose={false} maxWidth="lg">
          <ViewPurch {...{ setShowForm, viewData }} />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(PurchaseReport);
