import { SearchRounded } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { Input } from "../../controls";
import * as actions from "../../api/actions/pos";
import { useEffect } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { action, controller, Server } from "../../api/Server";

const SearchProd = ({ setProds, handleCart, getDefaultPosItems }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { selStore } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    // Set Focuse in Searchbox
    document.getElementById("searchBox").focus();
    return () => {};
  }, []);

  const handleSearch = (e) => {
    const onComplete = (res) => {
      if (res.status === 200 && res.result.length === 1)
        handleCart(res.result[0]);
      setSearchTerm("");
    };
    if (e.target.value && e.which === 13) findProds(e.target.value, onComplete);
  };

  const findProds = (val, onComplete) => {
    Server(controller.Inventory + action.FindProducts).post(
      { storeId: selStore, value: val },
      onComplete
    );
  };

  const handleInpChg = (e) => {
    var val = e.target.value;
    setSearchTerm(val);

    // Find products
    val
      ? findProds(val, (res) => {
          res.status === 200 && setProds([...res.result]);
        })
      : getDefaultPosItems();
  };

  return (
    <Box className="shadow-xs mb-10" sx={{ borderTopRightRadius: "10px" }}>
      <Input
        placeholder={t("enterNameOrScanBarcode")}
        name="barcode"
        id="searchBox"
        sx={{
          borderTopRightRadius: "10px",
          " .MuiFilledInput-root": {
            backgroundColor: "#fff !important",
          },
        }}
        value={searchTerm}
        onChange={handleInpChg}
        onKeyDown={handleSearch}
        fullWidth
        autoFocus={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchRounded />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  _defStore: state.pos.defStore,
  _type: state.pos.type,
});

const mapActionToProps = {
  _getByObj: actions.getByObj,
  _getById: actions.getById,
  _setVal: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(SearchProd);
