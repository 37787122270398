import { CloseRounded } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";

const ViewProd = (props) => {
  const { setShowForm, id, _getById } = props;
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const [cashier, setCashier] = useState();
  const [totalItem, setTotalItem] = useState(0);
  const [value, setValue] = useState({});

  useEffect(() => {
    if (id) {
      _getById(endpoints.Sell + controller.GetById, id, null, (res) => {
        if (res.status === 200 && res.result && res.result.id) {
          var _result = res.result;
          setValue(_result);
          setCashier(_result.user.firstName + " " + _result.user.lastName);
          setTotalItem(
            _result.sellItems
              .map((j) => parseFloat(j.qty))
              .reduce((x, y) => x + y, 0)
          );
        }
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      {org && value.id ? (
        <div>
          <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
            <div>
              <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
                {t("saleDetails")}
              </h1>
            </div>

            <div>
              <IconButton onClick={() => setShowForm(false)}>
                <CloseRounded />
              </IconButton>
            </div>
          </div>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
              <TableContainer className="mt-20">
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("itemName")}</TableCell>
                      <TableCell>{t("quantity")}</TableCell>
                      <TableCell>{t("price")}</TableCell>
                      <TableCell>{t("total")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {value.sellItems.map((item, i) => (
                      <TableRow key={i}>
                        <TableCell>{item.product.name}</TableCell>
                        <TableCell>{item.qty}</TableCell>
                        <TableCell>
                          {org.curSymbol +
                            " " +
                            item.price.toFixed(org.decimal)}
                        </TableCell>
                        <TableCell>
                          {org.curSymbol +
                            " " +
                            (item.qty * item.price).toFixed(org.decimal)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider />
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="w-100pr"
              sx={{
                borderRight: { sm: "1px solid var(--grayLight-color)" },
              }}
            >
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("invoiceID")}</TableCell>
                      <TableCell align="right">{value.id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("method")}</TableCell>
                      <TableCell align="right" className="tx-upp">
                        {value.payMethod && value.payMethod.title}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("cashier")}</TableCell>
                      <TableCell align="right">{cashier}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("status")}</TableCell>
                      <TableCell align="right">
                        <div className="fw-5">
                          {value.sellType === 2 ? (
                            <span className="bg-errorSM error-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
                              {t("due")}
                            </span>
                          ) : (
                            <span className="bg-successXS success-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
                              {t("paid")}
                            </span>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("sellDate")}</TableCell>
                      <TableCell align="right">{value.sellDate}</TableCell>
                    </TableRow>
                    {value.sellType === 2 && (
                      <>
                        <TableRow>
                          <TableCell>{t("dueDate")}</TableCell>
                          <TableCell align="right">{value.dueDate}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t("paidDate")}</TableCell>
                          <TableCell align="right">{value.paidDate}</TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableHead>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={6} className="w-100pr">
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("totalItem")}</TableCell>
                      <TableCell align="right">{totalItem}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("subtotal")}</TableCell>
                      <TableCell align="right">
                        {org.curSymbol +
                          " " +
                          value.subtotal.toFixed(org.decimal)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {t("tax")}({value.taxPct}%)
                      </TableCell>
                      <TableCell align="right">
                        {org.curSymbol + " " + value.tax.toFixed(org.decimal)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("discount")}</TableCell>
                      <TableCell align="right">
                        -{" "}
                        {org.curSymbol +
                          " " +
                          value.disAmt.toFixed(org.decimal)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="primary-color">
                        {t("totalPayableAmt")}
                      </TableCell>
                      <TableCell align="right" className="primary-color">
                        {org.curSymbol +
                          " " +
                          parseFloat(value.totalPay).toFixed(org.decimal)}
                      </TableCell>
                    </TableRow>
                    {value.sellType === 2 && (
                      <TableRow>
                        <TableCell className="error-color">
                          {t("dueAmount")}
                        </TableCell>
                        <TableCell align="right" className="error-color">
                          -{" "}
                          {org.curSymbol +
                            " " +
                            value.dueAmt.toFixed(org.decimal)}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell className="success-color">
                        {t("received")}
                      </TableCell>
                      <TableCell align="right" className="success-color">
                        {org.curSymbol +
                          " " +
                          value.received.toFixed(org.decimal)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={
                          value.changeAddedCredit ? "success-color" : ""
                        }
                      >
                        {value.changeAddedCredit
                          ? t("creditBalance")
                          : t("change")}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={
                          value.changeAddedCredit ? "success-color" : ""
                        }
                      >
                        {org.curSymbol +
                          " " +
                          value.change.toFixed(org.decimal)}
                      </TableCell>
                    </TableRow>
                    {value.diffAmt > 0 && (
                      <TableRow>
                        <TableCell className="error-color">
                          {t("diffAmt")}
                        </TableCell>
                        <TableCell align="right" className="error-color">
                          - {org.curSymbol + " " + value.diffAmt}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ViewProd);
