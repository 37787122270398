import { CloseRounded } from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "../../../layout";

const ViewChanges = ({ viewData, setShowForm }) => {
  const { t } = useTranslation();
  // const [changes, setChanges] = useState({
  //   newValue: {},
  //   oldValue: {},
  // });

  // useEffect(() => {
  //   if (viewData && viewData.changes) {
  //     var result = JSON.parse(viewData.changes);
  //     // result && setChanges(result);
  //   }
  //   return () => {};
  // }, [viewData]);

  return (
    <>
      <div className="d-grid">
        {/* Header */}
        <div className="d-flex g-10 a-center j-between pl-10 pr-10 pt-5">
          <div>
            <span className="fs-18 fw-5 gray-color tx-upp">
              {t("viewChanges")}
            </span>
          </div>
          <IconButton onClick={() => setShowForm(false)} size="small">
            <CloseRounded />
          </IconButton>
        </div>
        <Divider />

        {/* Body */}
        <Table
          ActionComp={() => <></>}
          HeaderComp={() => <></>}
          tblHead={[
            { id: "id", label: t("id") },
            { id: "name", label: t("description") },
            { id: "barcode", label: t("parentCategory") },
            { id: "categoryId", label: t("parentCategory") },
            { id: "subcategoryId", label: t("parentCategory") },
            { id: "unitType", label: t("parentCategory") },
            { id: "tax", label: t("parentCategory") },
            { id: "tax", label: t("parentCategory") },
            { id: "tax", label: t("parentCategory") },
          ]}
          tblData={[{}]}
        />
      </div>
    </>
  );
};

export default ViewChanges;
