import { CloseRounded } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const ViewData = (props) => {
  const { setShowForm, items } = props;
  const { org } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
        <div>
          <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
            {t("transferItems")}
          </h1>
        </div>

        <div>
          <IconButton onClick={() => setShowForm(false)}>
            <CloseRounded />
          </IconButton>
        </div>
      </div>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <TableContainer className="mt-20">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("itemName")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("unitPrice")}</TableCell>
                  <TableCell>{t("subtotal")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.length > 0 &&
                  items.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell>{item.product.name}</TableCell>
                      <TableCell>{item.qty}</TableCell>
                      <TableCell>
                        {(org && org.curSymbol) +
                          " " +
                          item.product.sellPrice.toFixed(org.decimal)}
                      </TableCell>
                      <TableCell>
                        {(org && org.curSymbol) +
                          " " +
                          (item.qty * item.product.sellPrice).toFixed(
                            org.decimal
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewData;
