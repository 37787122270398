import React from "react";
import { Form } from "../../../layout";
import { Divider, Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Model, UseForm } from "../../../hooks";
import { Button, Input } from "../../../controls";
import { MaxLength } from "../../../hooks/Method";
import { CloseRounded } from "@mui/icons-material";

const AddSellPrice = ({ setShowForm, setProduct, setProdErrors }) => {
  const { t } = useTranslation();
  const { SellPriceMdl } = Model();

  const { values, errors, setErrors, handleInpChg, resetFormControls } =
    UseForm(SellPriceMdl);

  const validateForm = () => {
    let temp = {};
    temp.title = values.title !== "" ? "" : t("titleIsReq");
    temp.price = values.price !== "" ? "" : t("sellPriceIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (isAddAnother = false) => {
    if (validateForm()) {
      setProduct((j) => ({
        ...j,
        sellPrices: [...j.sellPrices, values],
      }));
      setProdErrors((j) => ({ ...j, sellPrices: "" }));
      if (isAddAnother) {
        resetFormControls();
      } else setShowForm(false);
    }
  };

  const handleClose = () => {
    resetFormControls();
    setShowForm(false);
  };

  return (
    <Form noValidate>
      <Grid container spacing={3} className="bg-grayXS">
        <Grid item xs={12}>
          <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
            <div>
              <span className="tx-upp fs-20 fw-5 gray-color border-left">
                {t("addSellPrice")}
              </span>
            </div>

            <IconButton size="small" onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </div>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <div className="pl-15 pr-15">
            <Input
              label={t("title")}
              name="title"
              value={values.title}
              error={errors.title}
              onChange={handleInpChg}
              inputProps={{ maxLength: 30 }}
              required
              fullWidth
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="pl-15 pr-15">
            <Input
              label={t("price")}
              onInput={(e) => (e.target.value = MaxLength(e.target.value, 12))}
              name="price"
              value={values.price}
              error={errors.price}
              onChange={handleInpChg}
              fullWidth
              required
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="pl-15 pr-15 pb-15 tx-right d-flex g-10">
            <Button
              onClick={() => handleSubmit(false)}
              size="large"
              variant="text"
              fullWidth
            >
              {t("saveAndExit")}
            </Button>{" "}
            <Button
              onClick={() => handleSubmit(true)}
              variant="text"
              className="w-100pr"
              size="large"
              fullWidth
            >
              {t("saveAndAddAnother")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AddSellPrice;
