import { Grid } from "@mui/material";
import React from "react";
import { Input, InputAuto } from "../../controls";
import { useTranslation } from "react-i18next";
import { Countries } from "../../hooks/Countries";

const AddressFields = ({ values, errors, handleInpChg }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <InputAuto
          label={t("country")}
          name="country"
          value={values.country}
          error={errors.country}
          onChange={handleInpChg}
          options={Countries}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          label={t("city")}
          name="city"
          value={values.city}
          error={errors.city}
          onChange={handleInpChg}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Input
          label={t("state")}
          name="state"
          value={values.state}
          error={errors.state}
          onChange={handleInpChg}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Input
          label={t("ZIP")}
          name="zip"
          value={values.zip}
          error={errors.zip}
          onChange={handleInpChg}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label={t("address")}
          name="address"
          multiline
          rows={2}
          value={values.address}
          error={errors.address}
          onChange={handleInpChg}
        />
      </Grid>
    </Grid>
  );
};

export default AddressFields;
