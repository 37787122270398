import React, { useEffect } from "react";
import { InputCheck } from "../../../controls";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";

const PosAccess = ({ setFeaturesAccess, featuresAccess }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!featuresAccess.id) {
      featuresAccess.isHavePosAccess
        ? setFeaturesAccess((j) => ({
            ...j,
            pos: {
              isHaveGivingDiscountAccess: true,
              isHaveDueSaleAccess: true,
            },
          }))
        : setFeaturesAccess((j) => ({
            ...j,
            pos: {
              isHaveGivingDiscountAccess: false,
              isHaveDueSaleAccess: false,
            },
          }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresAccess.isHavePosAccess]);

  const handleInpChg = (e) => {
    const { name, checked } = e.target;
    setFeaturesAccess((j) => ({
      ...j,
      pos: { ...j.pos, [name]: checked },
    }));
  };

  return (
    <Collapse in={featuresAccess.isHavePosAccess || false}>
      <div className="ml-25 d-grid g-5 mt-5">
        <InputCheck
          label={t("isHaveDueSaleAccess")}
          checked={
            (featuresAccess.pos && featuresAccess.pos.isHaveDueSaleAccess) ||
            false
          }
          name={"isHaveDueSaleAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveGivingDiscountAccess")}
          checked={
            (featuresAccess.pos &&
              featuresAccess.pos.isHaveGivingDiscountAccess) ||
            false
          }
          name={"isHaveGivingDiscountAccess"}
          onClick={handleInpChg}
        />
      </div>
    </Collapse>
  );
};

export default PosAccess;
