import { SearchRounded } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";
import React, { useContext, useState } from "react";
import { Input } from "../../controls";
import { useEffect } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const SearchCats = ({ cats, setFltCats }) => {
  const { selStore } = useContext(AppContext);
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    document.getElementById("searchBox").focus();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleInpChg = (e) => {
    var val = e.target.value;
    setSearchTerm(val);

    setFltCats([...cats.filter((j) => j.name.toLowerCase().includes(val))]);
  };

  return (
    <Box className="shadow-xs mb-10" sx={{ borderTopRightRadius: "10px" }}>
      <Input
        placeholder={t("searchCategory")}
        name="category"
        id="searchBox"
        sx={{
          borderTopRightRadius: "10px",
          " .MuiFilledInput-root": {
            backgroundColor: "#fff !important",
          },
        }}
        value={searchTerm}
        onChange={handleInpChg}
        fullWidth
        autoFocus={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchRounded />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchCats;
