import React, { useEffect } from "react";
import { InputCheck } from "../../../controls";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";

const SellsAccess = ({ setFeaturesAccess, featuresAccess }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!featuresAccess.id) {
      featuresAccess.isHaveSellsAccess
        ? setFeaturesAccess((j) => ({
            ...j,
            sells: {
              isHaveViewAccess: true,
              isHaveExportAccess: true,
            },
          }))
        : setFeaturesAccess((j) => ({
            ...j,
            sells: {
              isHaveViewAccess: false,
              isHaveExportAccess: false,
            },
          }));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresAccess.isHaveSellsAccess]);

  const handleInpChg = (e) => {
    const { name, checked } = e.target;
    setFeaturesAccess((j) => ({
      ...j,
      sells: { ...j.sells, [name]: checked },
    }));
  };

  return (
    <Collapse in={featuresAccess.isHaveSellsAccess || false}>
      <div className="ml-25 d-grid g-5 mt-5">
        <InputCheck
          label={t("isHaveViewAccess")}
          checked={
            (featuresAccess.sells && featuresAccess.sells.isHaveViewAccess) ||
            false
          }
          name={"isHaveViewAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveExportAccess")}
          checked={
            (featuresAccess.sells && featuresAccess.sells.isHaveExportAccess) ||
            false
          }
          name={"isHaveExportAccess"}
          onClick={handleInpChg}
        />
      </div>
    </Collapse>
  );
};

export default SellsAccess;
