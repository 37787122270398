import { Avatar, Divider, Grid, InputAdornment } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button, Input, InputAuto, InputDate } from "../../../controls";
import { useTranslation } from "react-i18next";
import * as actions from "../../../api/actions/pos";
import { connect } from "react-redux";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import { DropLi } from "../../../hooks";
import { Dialog } from "../../../layout";
import AddEditCstmr from "../../customer/AddEditCstmr";

const CustomerSect = (props) => {
  const {
    _getById,
    values,
    setValues,
    errors,
    handleInpChg,
    handleInpDate,
    handleSubmit,
    loading,
    store,
    setTotalPay,
    setTax,
    setTaxPct,
    access,
    needToPayAmt,
  } = props;
  const { selStore, org } = useContext(AppContext);
  const { t } = useTranslation();
  const { SellType } = DropLi();
  const [selCstmr, setSelCstmr] = useState({});
  const [cstmrs, setCstmrs] = useState([]);
  const [cstmrAccBal, setCstmrAccBal] = useState(0);
  const [showCstmrForm, setShowCstmrForm] = useState(false);

  useEffect(() => {
    // Get Customers
    _getById(
      endpoints.Customer + controller.GetAllByStore,
      selStore,
      null,
      (res) => {
        if (res && res.status === 200)
          setCstmrs([
            ...res.result.map((j) => ({
              label: j.firstName + " " + j.lastName,
              subtitle: j.cstmrCode + " | " + j.phone,
              ...j,
            })),
          ]);
      }
    );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    if (selCstmr.id) {
      _getById(
        endpoints.Customer + controller.GetCstmrAccBal,
        selCstmr.id,
        null,
        (res) => setCstmrAccBal(res.result)
      );
    } else setCstmrAccBal(0);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selCstmr]);

  /* Handle Customer & Tax Calculation */
  const handleCstmr = (e) => {
    var cstmrVal = cstmrs.find((j) => j.id === e.target.value);

    // Calculate Tax Item;
    var _taxItemTotal = values.sellItems
      .filter((j) => j.tax === 1)
      .map((j) => j.qty * j.price)
      .reduce((x, y) => x + y, 0)
      .toFixed(org.decimal);
    // Find out tax Percentage;
    var _taxPct = parseFloat(
      cstmrVal && cstmrVal.specialTaxPayer ? cstmrVal.vatTax || 0 : store.tax
    );
    setTaxPct(_taxPct);
    // Calculate Tax Amount;
    var _tax = (_taxPct / 100) * _taxItemTotal || 0;

    if (cstmrVal) {
      setSelCstmr(cstmrVal);
      if (cstmrVal.taxExempt) {
        setValues((j) => ({
          ...j,
          tax: "",
          taxPct: "",
          wasSpecialTaxpayer: false,
        }));
        setTotalPay(values.subtotal);
        setTax(0);
        setTaxPct(0);
      } else if (!cstmrVal.taxExempt) {
        setTaxAction(_tax, _taxPct);
        setValues((j) => ({
          ...j,
          wasSpecialTaxpayer: cstmrVal.specialTaxPayer,
        }));
      }
    } else {
      setSelCstmr({});
      setTaxAction(_tax, _taxPct);
    }

    setValues((j) => ({
      ...j,
      cstmrId: e.target.value,
      sellType: !e.target.value ? 1 : j.sellType,
      taxExempt: cstmrVal ? cstmrVal.taxExempt : false,
    }));
  };

  const setTaxAction = (taxAmt = 0, taxPct = 0) => {
    setTotalPay(values.subtotal + taxAmt);
    setTax(taxAmt);
    setValues((j) => ({
      ...j,
      taxPct: taxPct,
      tax: taxAmt,
    }));
  };

  const handleAddCstmr = () => {
    setShowCstmrForm(!showCstmrForm);
  };

  const handleSellType = (id) => {
    setValues((j) => ({ ...j, sellType: id, dueAmt: "", payments: [] }));
  };

  const handleDue = (e) => {
    var inpVal = e.target.value;
    if (
      parseFloat(inpVal) <= parseFloat(needToPayAmt) ||
      values.dueAmt.length > inpVal.length
    )
      handleInpChg(e);
  };

  const handleCredit = (e) => {
    var inpVal = e.target.value;
    if (
      parseFloat(inpVal) <= parseFloat(cstmrAccBal) ||
      values.creditAmt.length > inpVal.length
    )
      handleInpChg(e);
  };

  return org ? (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <div className="d-flex">
            <div className="w-100pr">
              <InputAuto
                className="fs-20"
                variant="outlined"
                placeholder={t("selectCustomer")}
                value={selCstmr.id}
                options={cstmrs}
                onChange={handleCstmr}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "22px",
                    fontWeight: "500",
                    color: "var(--gray-color)",
                  },
                }}
              />
            </div>
            {!selCstmr.id && (
              <div className="d-flex ml-10">
                <Button
                  onClick={handleAddCstmr}
                  variant="outlined"
                  size="small"
                  className="h-100pr fs-14 mr-10"
                  sx={{ lineHeight: "20px" }}
                >
                  {t("addCustomer")}
                </Button>
              </div>
            )}
            {access.isHaveDueSaleAccess && selCstmr.id && (
              <div className="d-flex g-5 ml-10">
                {SellType &&
                  SellType.map((type, ind) => (
                    <Button
                      key={ind}
                      variant="text"
                      size="small"
                      onClick={() => handleSellType(type.id)}
                      className={`h-100pr fs-16 ${
                        type.id === values.sellType && "bg-primary white-color"
                      }`}
                    >
                      {type.label}
                    </Button>
                  ))}
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant="text"
            onClick={handleSubmit}
            fullWidth
            loading={loading}
            className="bg-error white-color d-flex h-100pr fs-24"
          >
            {t("sale")}
          </Button>
        </Grid>
        {values.cstmrId && (
          <Grid item xs={12}>
            <div className="d-flex g-15 a-center j-between mt-5">
              <div className="d-flex g-15">
                <Avatar sx={{ width: 50, height: 50 }} src={selCstmr.photo} />
                <div className="d-grid gray-color">
                  <span className="fs-20 fw-5">{selCstmr.label}</span>
                  <span className="fs-14">{selCstmr.subtitle}</span>
                </div>
              </div>

              <div className="d-grid g-10 gray-color">
                <div className="ml-10">
                  {org.curSymbol}&nbsp;
                  <span className="fs-20 fw-5">
                    {cstmrAccBal.toFixed(org.decimal)}
                    &nbsp;
                  </span>
                  <span className="fs-12 tx-upp">{t("storeCredit")}</span>
                </div>
                {cstmrAccBal > 0 && (
                  <div>
                    <Input
                      fullWidth
                      placeholder={t("useCredit")}
                      name="creditAmt"
                      value={values.creditAmt}
                      error={errors.creditAmt}
                      onChange={handleCredit}
                      className="rmv-arrow br-8"
                      variant="outlined"
                      type="number"
                      size="small"
                      onInput={(e) =>
                        e.target.value > 0 ? e : (e.target.value = "")
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span className="gray-color ">{org.curSymbol}</span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              </div>

              {/* Due Properties */}
              {values.sellType === 2 && (
                <div className="d-grid gray-color g-5">
                  <Input
                    fullWidth
                    placeholder={t("dueAmount")}
                    name="dueAmt"
                    value={values.dueAmt}
                    error={errors.dueAmt}
                    onChange={handleDue}
                    className="rmv-arrow br-8"
                    variant="outlined"
                    size="small"
                    onInput={(e) =>
                      e.target.value > 0 ? e : (e.target.value = "")
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span className="gray-color ">{org.curSymbol}</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <InputDate
                    placeholder={t("dueDate")}
                    name="dueDate"
                    value={values.dueDate}
                    error={errors.dueDate}
                    variant="outlined"
                    size="small"
                    minDate={new Date()}
                    views={["day", "month"]}
                    onChange={(date) =>
                      handleInpDate(
                        "dueDate",
                        new Date(date).toLocaleDateString()
                      )
                    }
                  />
                </div>
              )}
            </div>
          </Grid>
        )}
      </Grid>
      <Divider className="mt-10 mb-5" />

      {showCstmrForm && (
        <Dialog show={showCstmrForm} onClose={false} maxWidth="lg">
          <AddEditCstmr
            {...{
              setOpen: setShowCstmrForm,
              openOnDialog: true,
              setCstmrs,
              setSelCstmr,
              setInvVal: setValues,
            }}
          />
        </Dialog>
      )}
    </>
  ) : (
    "Loading..."
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(CustomerSect);
