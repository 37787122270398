import React, { useContext, useState } from "react";
import { Table } from "../../layout";
import { AppContext } from "../../App";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CSVLink } from "react-csv";
import { ChangeArrayKey } from "../../hooks/Method";
import { action, controller, Server } from "../../api/Server";

const TopSelling = () => {
  const { setLoader, selStore, org, setPageTitle } = useContext(AppContext);
  const { t } = useTranslation();
  const [sells, setSells] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      Server(controller.Report + action.TopSellingProducts).getById(
        selStore,
        (res) => {
          setLoader(false);
          if (res && res.status === 200) {
            var result = res.result;
            result &&
              result.length > 0 &&
              setSells([
                ...result.map((j) => ({
                  ...j,
                  totalSale:
                    org.curSymbol + " " + j.totalSale.toFixed(org.decimal),
                })),
              ]);
          }
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    setPageTitle(t("topSelling"));
    setCsvHeader([
      { key: "productName", label: t("productName") },
      { key: "categoryName", label: t("cateogry") },
      { key: "totalItemSold", label: t("itemSold") },
      { key: "totalSale", label: t("totalSale") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={sells}
          headers={csvHeader}
          filename={`${t("topSelling")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={HeaderComp}
        tblHead={[...ChangeArrayKey(csvHeader, "key", "id")]}
        tblData={sells}
      />
    </>
  );
};

export default TopSelling;
