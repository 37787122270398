import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { Input, Button } from "../../controls";
import { Form } from "../../layout";
import { Model, UseForm } from "../../hooks";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../hooks/Method";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const AddEditCurr = (props) => {
  const { updData, setCurrs, setShowForm, setAlert, _post, _put } = props;
  const { t } = useTranslation();
  const { selStore } = useContext(AppContext);
  const { CurrMdl } = Model();
  const [loading, setLoading] = useState(false);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(CurrMdl);

  const validateForm = () => {
    let temp = {};
    temp.currency = values.currency !== "" ? "" : t("currencyIsRequired");
    temp.symbol = values.symbol !== "" ? "" : t("symbolIsRequired");
    temp.name = values.name !== "" ? "" : t("nameIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowForm(true);
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        if (res && (res.status === 201 || res.status === 200)) {
          if (setCurrs && res.result.id) {
            updData
              ? setCurrs((x) => [
                  ...x.map((j) => (j.id === res.result.id ? res.result : j)),
                ])
              : setCurrs((x) => [res.result, ...x]);
          }
          setAlert({
            isOpen: true,
            type: "success",
            title: t("success"),
            subTitle: res.msg,
          });
          setShowForm(false);
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: res.msg,
          });
      };
      if (updData && updData.id) {
        _put(
          endpoints.Currency + controller.Put,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      } else {
        _post(
          endpoints.Currency + controller.Post,
          ToFormData(RmvEmptyProp({ ...values, storeId: selStore })),
          null,
          onComplete
        );
      }
    }
  };

  useEffect(() => {
    updData && setValues((j) => ({ ...j, ...updData }));
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  // console.log("values: ", values);

  return (
    <div className="bg-grayXS">
      <Form noValidate onSubmit={handleSubmit}>
        <div className="d-flex flex-wrap g-15 j-between p-10">
          <div>
            <Typography
              variant="span"
              className="fs-20 gray-color fw-4 tx-upp border-left"
            >
              {updData === undefined ? t("addNew") : t("update")}{" "}
              {t("currency")}
            </Typography>
          </div>

          <Box
            className="d-flex g-10"
            sx={{ width: { sm: "auto", xs: "100%" } }}
          >
            <Button
              onClick={() => setShowForm(false)}
              className="error-color bg-errorSM w-100pr"
            >
              {t("close")}
            </Button>{" "}
            <Button
              loading={loading}
              type="submit"
              className="w-100pr btn-primary"
            >
              {updData === undefined ? t("submit") : t("saveChanges")}
            </Button>
          </Box>
        </div>
        <Divider className="mb-15" />
        <Grid container spacing={2.5} className="p-10">
          <Grid item xs={6} md={2}>
            <Input
              label={t("symbol")}
              name="symbol"
              required={true}
              inputProps={{ maxLength: 10 }}
              value={values.symbol}
              error={errors.symbol}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Input
              inputProps={{ maxLength: 100 }}
              label={t("currency")}
              name="currency"
              required={true}
              value={values.currency}
              error={errors.currency}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              inputProps={{ maxLength: 100 }}
              label={t("name")}
              name="name"
              required={true}
              value={values.name}
              error={errors.name}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              inputProps={{ maxLength: 100 }}
              label={t("currencyUnit")}
              name="currUnit"
              value={values.currUnit}
              error={errors.currUnit}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              inputProps={{ maxLength: 100 }}
              label={t("currencySubunit")}
              name="currSubunit"
              value={values.currSubunit}
              error={errors.currSubunit}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditCurr);
