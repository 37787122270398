import React, { useContext, useState } from "react";
import { Button } from "../../../controls";
import { AddRounded } from "@mui/icons-material";
import { Dialog } from "../../../layout";
import AddSellPrice from "./AddSellPrice";
import { Chip } from "@mui/material";
import { AppContext } from "../../../App";

const InputSellPrice = ({ values, setValues, error, setErrors }) => {
  const [showForm, setShowForm] = useState(false);
  const { org } = useContext(AppContext);

  const handleDelete = (key) => {
    setValues((j) => ({
      ...j,
      sellPrices: [...j.sellPrices.filter((x) => x.key !== key)],
    }));
  };

  return (
    <>
      <div className="d-flex a-center h-100pr g-10 flex-wrap">
        {/* Price List */}
        <div className="d-flex a-center g-10 flex-wrap">
          {values.sellPrices.map((item, ind) => (
            <Chip
              key={ind}
              color="primary"
              className=" fs-15 pt-5 pb-5"
              label={`${item.title}:  ${
                (org && org.curSymbol) + " " + item.price
              }`}
              onDelete={() => handleDelete(item.key)}
            />
          ))}
        </div>

        {/* Add Price */}
        <div>
          <Button
            onClick={() => setShowForm(!showForm)}
            startIcon={<AddRounded />}
            variant="text"
            sx={{ pl: "15px", pr: "15px", borderRadius: "25px" }}
          >
            Sell Price
          </Button>
        </div>

        {/* Error Message */}
        {error && <span className="error-color fs-12">{error}</span>}
      </div>

      {/* Edit Dialog */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="xs">
          <AddSellPrice
            {...{
              setShowForm,
              setProduct: setValues,
              setProdErrors: setErrors,
            }}
          />
        </Dialog>
      )}
    </>
  );
};

export default InputSellPrice;
