import { Grid } from "@mui/material";
import React from "react";
import { Input } from "../../controls";

const AddressFields = ({ values, errors, t, handleInpChg }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Input
          label={t("city")}
          name="city"
          value={values.city}
          error={errors.city}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 400 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("state")}
          name="state"
          value={values.state}
          error={errors.state}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 400 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("ZIP")}
          name="zip"
          value={values.zip}
          error={errors.zip}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 400 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          label={t("address")}
          name="address"
          value={values.address}
          error={errors.address}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 800 }}
        />
      </Grid>
    </Grid>
  );
};

export default AddressFields;
