import React, { useContext, useEffect, useState } from "react";
import * as actions from "../../../api/actions/pos";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../App";
import { Box, IconButton, Tooltip } from "@mui/material";
import { PaymentRounded, VisibilityRounded } from "@mui/icons-material";
import { Dialog, Table } from "../../../layout";
import { InputDate } from "../../../controls";
import { subDays } from "date-fns";
import moment from "moment";
import ViewPurch from "../../purchase/ViewPurch";
import MakePayment from "./MakePayment";

const PurchReceipt = ({ values, setValues, _getById }) => {
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const [viewData, setViewData] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [showPayForm, setShowPayForm] = useState(false);
  const [fltPurch, setFltPurch] = useState([...values.purchases]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });

  useEffect(() => {
    values && setFltPurch([...values.purchases]);
    return () => {};
  }, [values]);

  const handleDate = (name, newVal) => {
    setSortDate((x) => ({ ...x, [name]: newVal }));
  };

  const handleView = (data) => {
    setViewData({
      ...data,
      supplier: values.firstName + " " + values.lastName,
    });
    setShowForm(!showForm);
  };

  const handleViewPay = (data) => {
    setPaymentData(data);
    setShowPayForm(true);
  };

  useEffect(() => {
    setFltPurch([
      ...values.purchases.filter(
        (j) =>
          moment(j.purchDate, "MM/DD/YYYY") >=
            moment(sortDate.fromDate, "MM/DD/YYYY") &&
          moment(j.purchDate, "MM/DD/YYYY") <=
            moment(sortDate.toDate, "MM/DD/YYYY")
      ),
    ]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDate, values.purchases]);

  const HeaderComp = () => (
    <Box className="d-flex a-center w-100pr">
      <InputDate
        variant="outlined"
        fullWidth
        size="small"
        name="fromDate"
        value={sortDate.fromDate}
        views={["day", "month", "year"]}
        openTo="day"
        minDate={new Date("01/01/2000")}
        maxDate={subDays(new Date(sortDate.toDate), 1)}
        onChange={(date) =>
          handleDate("fromDate", new Date(date).toLocaleDateString())
        }
      />
      <span className="ml-10 mr-10 fs-16 dark-color">TO</span>
      <InputDate
        variant="outlined"
        fullWidth
        size="small"
        name="toDate"
        value={sortDate.toDate}
        minDate={moment(sortDate.fromDate, "MM/DD/YYYY").add(1, "days")}
        views={["day", "month", "year"]}
        openTo="day"
        onChange={(date) =>
          handleDate("toDate", new Date(date).toLocaleDateString())
        }
      />
    </Box>
  );

  const ActionComp = (props) => (
    <Box className="d-flex g-5">
      <Tooltip arrow title={t("view")}>
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      {props.paymentDue > 0 && (
        <Tooltip arrow title={t("payment")}>
          <IconButton
            onClick={() => handleViewPay(props)}
            size="small"
            className="mr-5"
          >
            <PaymentRounded className="fs-18 m-2 success-color" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  return (
    <>
      {values ? (
        <Table
          ActionComp={ActionComp}
          HeaderComp={HeaderComp}
          tblHead={[
            { id: "actions", label: t("actions") },
            { id: "status", label: t("status") },
            { id: "sellType", label: t("sellType") },
            { id: "summary", label: t("summary") },
            { id: "account", label: t("account") },
            { id: "date", label: t("date") },
          ]}
          tblData={fltPurch.map((item) => {
            var status = item.paymentDue > 0 ? "Due" : "Paid";
            var isOverdue =
              moment(new Date()) > moment(item.payDate, "MM/DD/YYYY") &&
              item.paymentDue > 0
                ? true
                : false;
            return {
              ...item,
              refId: `reference no ${item.id}`,
              summary: (
                <div className="fs-14 g-5">
                  <div>
                    <span>{t("referenceNo")}: </span>
                    <span className="fw-5">{item.id}</span>
                  </div>
                  <div>
                    <span>{t("totalItem")}: </span>
                    <span className="fw-5">{item.purchItems.length}</span>
                  </div>
                </div>
              ),
              sellType: item.paymentType === 1 ? "Full Paid" : "Due",
              statusTax: `status ${status}`,
              status: (
                <div className="fw-5">
                  {item.paymentDue > 0 ? (
                    <span className="bg-errorSM error-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
                      {status}
                    </span>
                  ) : (
                    <span className="bg-successXS success-color pr-10 pl-10 pt-5 pb-5 br-25 fs-12 tx-upp">
                      {status}
                    </span>
                  )}
                </div>
              ),
              account: (
                <div className="fs-14 d-grid g-5 tx-upp">
                  <div className="d-flex g-10">
                    <div>
                      <span>{t("paid")}:&nbsp;&nbsp;</span>
                      <span className="fw-5">
                        {org.curSymbol +
                          " " +
                          item.paidAmt.toFixed(org.decimal)}{" "}
                      </span>
                    </div>
                    |
                    <div className={item.paymentDue > 0 ? "error-color" : ""}>
                      <span>{t("due")}:&nbsp;&nbsp;</span>
                      <span
                        className={`fw-5 ${
                          item.paymentDue > 0 && "error-color"
                        }`}
                      >
                        {org.curSymbol +
                          " " +
                          item.paymentDue.toFixed(org.decimal)}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex g-10">
                    <div className="fw-5">
                      <span>{t("total")}:&nbsp;&nbsp;</span>
                      <span>
                        {org.curSymbol +
                          " " +
                          (
                            parseFloat(item.paidAmt) +
                            parseFloat(item.paymentDue)
                          ).toFixed(org.decimal)}
                      </span>
                    </div>
                  </div>
                </div>
              ),
              date: (
                <div className="fs-14 d-grid">
                  {item.nextPayDate && (
                    <div>
                      <span>{t("nextPayDate")}: </span>
                      <span
                        className={`fw-5 ${
                          new Date() > new Date(item.nextPayDate)
                            ? "error-color"
                            : ""
                        }`}
                      >
                        {item.nextPayDate}
                      </span>
                    </div>
                  )}
                  {item.payDate && item.paymentDue > 0 && (
                    <div className={isOverdue ? "error-color" : ""}>
                      <span>{t("dueDate")}: </span>
                      <span className="fw-5">{item.payDate}</span>
                    </div>
                  )}
                  {item.paidDate && (
                    <div>
                      <span>{t("paidDate")}: </span>
                      <span className="fw-5">{item.paidDate}</span>
                    </div>
                  )}

                  <div>
                    <span>{t("purchaseDate")}: </span>
                    <span className="fw-6">{item.purchDate}</span>
                  </div>
                </div>
              ),
            };
          })}
        />
      ) : (
        "Loading..."
      )}

      {/* Edit Dialog */}
      {viewData.id && (
        <Dialog show={showForm} maxWidth="lg" onClose={false}>
          <ViewPurch {...{ setShowForm, viewData }} />
        </Dialog>
      )}

      {showPayForm && (
        <Dialog show={showPayForm} onClose={false}>
          <MakePayment
            {...{
              setShowForm: setShowPayForm,
              paymentData,
              setSplr: setValues,
            }}
          />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(PurchReceipt);
