import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { CloseRounded } from "@mui/icons-material";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";

const ViewRegister = ({ setShowForm, viewData, _getById }) => {
  const { t } = useTranslation();
  const { org, stores, selStore } = useContext(AppContext);
  const [store, setStore] = useState({});
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();

  useEffect(() => {
    setStore(stores.find((j) => j.id === selStore));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    if (viewData) {
      setLoading(true);
      _getById(
        endpoints.Register + controller.GetById,
        viewData.id,
        null,
        (res) => {
          setLoading(false);
          if (res.status === 200) setValues({ ...res.result });
          else console.error("Server Error: ", res.details | "");
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewData]);

  const title_cls = {
    fontSize: { xs: "16px", md: "18px" },
    borderLeft: "0.4rem double var(--primary-color)",
    paddingLeft: "10px",
    textTransform: "uppercase",
  };

  return (
    <>
      {/* Hello world */}
      <div className="d-flex j-between a-center p-10">
        <span className="fs-22 gray-color tx-upp border-left">
          {t("cashRegisterReport")}
        </span>
        <IconButton onClick={() => setShowForm(false)}>
          <CloseRounded />
        </IconButton>
      </div>
      <Divider />
      {!loading ? (
        values && (
          <Grid container className="p-10">
            {/* Cashier Details */}
            <Grid item xs={12} md={6}>
              <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
                <Typography sx={title_cls}>{t("cashierDetails")}</Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("storeName")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.store.storeName}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("address")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.store.address}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("cashierName")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.user.firstName + " " + viewData.user.lastName}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("saleDate")}:
                  </Typography>
                  <Typography variant="subtitle1">{viewData.date}</Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("timeIn")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.openTime}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "140px", md: "180px" } }}
                  >
                    {t("timeOut")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.closeTime}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            </Grid>
            {/* Register Details */}
            <Grid item xs={12} md={6}>
              <Box className="mt-20 mb-30 w-100pr">
                <Typography sx={title_cls}>{t("registerDetails")}</Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalCustomers")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.totalCstmr}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("averagePerCustomer")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.averagePerCstmr}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalSaleRefund")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.totalRefund}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalVoidSells")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.totalVoidSale}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalItemCanceled")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.itemCancel.toLocaleString("en-US", {
                      maximumFractionDigits: 3,
                    })}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            </Grid>
            {/* Sale Report */}
            <Grid item xs={12} md={6}>
              <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
                <Typography sx={title_cls}>{t("saleReport")}</Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.totalSale.toLocaleString("en-US", {
                      maximumFractionDigits: 3,
                    })}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalCashSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.totalCash.toLocaleString("en-US", {
                      maximumFractionDigits: 3,
                    })}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalCreditSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.totalCradit.toLocaleString("en-US", {
                      maximumFractionDigits: 3,
                    })}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("otherPaymentReceived")}:&nbsp;
                  </Typography>
                  <Typography variant="subtitle1">
                    {" "}
                    {org && org.curSymbol}{" "}
                    {viewData.otherPayment.toLocaleString("en-US", {
                      maximumFractionDigits: 3,
                    })}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {/* Sell Details */}
            <Grid item xs={12} md={6}>
              <Box className="mt-20 mb-30 w-100pr">
                <Typography sx={title_cls}>{t("sellDetails")}</Typography>
                <Divider className="pt-10 mb-5" />

                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalGrossSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.totalSale}
                  </Typography>
                </Box>
                <Divider />

                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalTaxItemSold")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.taxItemSold}
                  </Typography>
                </Box>

                <Divider />

                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalNonTaxItemSold")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.nonTaxItemSold}
                  </Typography>
                </Box>

                <Divider />

                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "180px", md: "200px" } }}
                  >
                    {t("totalTaxReceived")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {org && org.curSymbol} {viewData.taxReceived}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {/* Money */}
            <Grid item xs={12}>
              <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
                <Typography sx={title_cls}>{t("money")}</Typography>
                <Divider className="pt-10 mb-5" />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "220px", md: "250px" } }}
                  >
                    {t("totalCashSale")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {viewData.totalCash}
                  </Typography>
                </Box>
                <Divider />
                <Box className="d-flex flex-wrap">
                  <Typography
                    variant="subtitle1"
                    className="fw-5 gray-color"
                    sx={{ minWidth: { xs: "220px", md: "250px" } }}
                  >
                    {t("shiftTransactionsBalance")}:
                  </Typography>
                  <div className="d-flex a-center g-10">
                    <Typography variant="subtitle1">
                      - {org && org.curSymbol} {viewData.shiftTransBalanace}
                    </Typography>
                  </div>
                </Box>
                <Divider />
                <Box className="d-flex">
                  <Typography
                    variant="subtitle1"
                    className="fw-5"
                    sx={{ minWidth: { xs: "220px", md: "250px" } }}
                  >
                    {t("totalDiffAmt")}:
                  </Typography>
                  <div className="d-flex a-center g-10">
                    <Typography variant="subtitle1">
                      {" "}
                      - {org.curSymbol + " " + values.reg.totalDiffAmt}
                    </Typography>
                  </div>
                </Box>
                <Divider />
              </Box>
            </Grid>
            {/* Products Sold by Category */}
            <Grid item xs={12}>
              <TableContainer className="mt-20">
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                  className="gray-color tx-upp"
                >
                  {t("productsSoldByCategory")}
                </Typography>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { padding: "0px" },
                      }}
                    >
                      <TableCell>{t("categoryName")}</TableCell>
                      <TableCell>{t("quantity")}</TableCell>
                      <TableCell>{t("price")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.sellsByCat.map((item, i) => {
                      var prodName = "";
                      var prod = item[0].product;

                      if (prod.parentCat) prodName = prod.parentCat.name;
                      else if (prod.category) prodName = prod.category.name;

                      return (
                        <TableRow
                          key={i}
                          sx={{ "td, th": { padding: "5px 0px" } }}
                        >
                          <TableCell>{prodName}</TableCell>
                          <TableCell>
                            {item.map((j) => j.qty).reduce((x, y) => x + y, 0)}
                          </TableCell>
                          <TableCell>
                            {(org && org.curSymbol) +
                              " " +
                              item
                                .map((j) => j.qty * j.price)
                                .reduce((x, y) => x + y, 0)
                                .toFixed(org.decimal)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {/* Details of products sold */}
            <Grid item xs={12}>
              <TableContainer className="mt-20">
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    borderLeft: "0.4rem double var(--primary-color)",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                  className="gray-color tx-upp"
                >
                  {t("detailsOfProductsSold")}
                </Typography>
                <Box sx={{ maxHeight: "300px !important", overflow: "auto" }}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            padding: "0px",
                          },
                        }}
                      >
                        <TableCell>{t("itemName")}</TableCell>
                        <TableCell className="w-70">{t("quantity")}</TableCell>
                        <TableCell className="w-90">{t("tax")}</TableCell>
                        <TableCell>{t("price")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.sells
                        .map((j) => j.sellItems)
                        .flat()
                        .map((item, i) => (
                          <TableRow
                            sx={{ "td, th": { padding: "5px 0px" } }}
                            key={i}
                          >
                            <TableCell>{item.product.name}</TableCell>
                            <TableCell>{item.qty}</TableCell>
                            <TableCell>
                              {item.itemType === 1 && item.product.tax === 1
                                ? `${
                                    (org && org.curSymbol) +
                                    " " +
                                    (
                                      (parseFloat(store.tax) / 100) *
                                      (item.price * item.qty)
                                    ).toFixed(org.decimal)
                                  }`
                                : t("noTax")}
                            </TableCell>
                            <TableCell>
                              {(org && org.curSymbol) +
                                " " +
                                (item.qty * item.price).toFixed(org.decimal)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </TableContainer>
            </Grid>
            {/* Notes */}
            <Grid item xs={12}>
              <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
                <Typography sx={title_cls}>{t("notes")}</Typography>
                <Divider className="pt-10 mb-5" />
                <Typography variant="subtitle1">{viewData.notes}</Typography>
              </Box>
            </Grid>
          </Grid>
        )
      ) : (
        <Typography variant="h5" className="fs-italic p-20 gray-color">
          Loading...
        </Typography>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ViewRegister);
