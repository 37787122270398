import { QueryStatsRounded } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";

const StoreValueReport = ({ _getById }) => {
  const { t } = useTranslation();
  const { org, selStore } = useContext(AppContext);
  const [invVal, setInvVal] = useState(0);

  useEffect(() => {
    selStore &&
      _getById(
        endpoints.HomeReport + controller.GetInvValue,
        selStore,
        null,
        (res) => res.status === 200 && setInvVal(res.result)
      );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <Paper className="p-15 bg-primary">
      <Box className="p-30 white-color">
        <Typography variant="h6" className="fs-20 fs-italic">
          {t("inStoreInventoryValue")}
        </Typography>
        <Box className="tx-center pt-40 pb-20">
          <QueryStatsRounded sx={{ fontSize: "100px" }} />
          <Typography
            variant="h5"
            className="fw-5 pt-20"
            sx={{ fontSize: "30px" }}
          >
            {(org && org.curSymbol) + " " + invVal.toFixed(org.decimal)}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _defStore: state.pos.defStore,
});

const mapActiontoProps = { _getById: actions.getById };

export default connect(mapStateToProps, mapActiontoProps)(StoreValueReport);
