import React, { useEffect } from "react";
import { InputCheck } from "../../../controls";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";

const CustomerAccess = ({ setFeaturesAccess, featuresAccess }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!featuresAccess.id) {
      featuresAccess.isHaveCustomerAccess
        ? setFeaturesAccess((j) => ({
            ...j,
            customer: {
              isHaveAddAccess: true,
              isHaveEditAccess: true,
              isHaveDeleteAccess: true,
              isHaveExportAccess: true,
              isHaveViewAccess: true,
              isHaveReceiptViewAccess: true,
              isHaveAccountsViewAccess: true,
            },
          }))
        : setFeaturesAccess((j) => ({
            ...j,
            customer: {
              isHaveAddAccess: false,
              isHaveEditAccess: false,
              isHaveDeleteAccess: false,
              isHaveExportAccess: false,
              isHaveViewAccess: false,
              isHaveReceiptViewAccess: false,
              isHaveAccountsViewAccess: false,
            },
          }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresAccess.isHaveCustomerAccess]);

  const handleInpChg = (e) => {
    const { name, checked } = e.target;
    setFeaturesAccess((j) => ({
      ...j,
      customer: { ...j.customer, [name]: checked },
    }));
  };

  return (
    <Collapse in={featuresAccess.isHaveCustomerAccess || false}>
      <div className="ml-25 d-grid g-5 mt-5">
        <InputCheck
          label={t("isHaveViewAccess")}
          checked={
            (featuresAccess.customer &&
              featuresAccess.customer.isHaveViewAccess) ||
            false
          }
          name={"isHaveViewAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveAddAccess")}
          checked={
            (featuresAccess.customer &&
              featuresAccess.customer.isHaveAddAccess) ||
            false
          }
          name={"isHaveAddAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveEditAccess")}
          checked={
            (featuresAccess.customer &&
              featuresAccess.customer.isHaveEditAccess) ||
            false
          }
          name={"isHaveEditAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveDeleteAccess")}
          checked={
            (featuresAccess.customer &&
              featuresAccess.customer.isHaveDeleteAccess) ||
            false
          }
          name={"isHaveDeleteAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveExportAccess")}
          checked={
            (featuresAccess.customer &&
              featuresAccess.customer.isHaveExportAccess) ||
            false
          }
          name={"isHaveExportAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveReceiptViewAccess")}
          checked={
            (featuresAccess.customer &&
              featuresAccess.customer.isHaveReceiptViewAccess) ||
            false
          }
          name={"isHaveReceiptViewAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveAccountsViewAccess")}
          checked={
            (featuresAccess.customer &&
              featuresAccess.customer.isHaveAccountsViewAccess) ||
            false
          }
          name={"isHaveAccountsViewAccess"}
          onClick={handleInpChg}
        />
      </div>
    </Collapse>
  );
};

export default CustomerAccess;
