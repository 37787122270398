import React from "react";
import { Select } from "../../controls";

const CartProdPriceInp = ({ item, setCartItems }) => {
  const handleInpChg = (e) => {
    var newPriceId = e.target.value;
    setCartItems((cartItem) => [
      ...cartItem.map((prod) => {
        if (item.prices && prod.productId === item.productId) {
          var priceDtl = item.prices.find((j) => j.id === newPriceId);
          return {
            ...prod,
            price: priceDtl.price.toFixed(3),
            priceId: priceDtl.id,
          };
        }
        return {
          ...prod,
        };
      }),
    ]);
  };

  return (
    <Select
      value={item.priceId}
      onChange={handleInpChg}
      options={item.prices || []}
      size="small"
      variant="outlined"
      className="d-flex a-end"
      sx={{
        "& .MuiInputBase-root": {
          minWidth: "120px",
          width: "fit-content",
        },
        "& .MuiInputBase-input": {
          padding: "6px 6px 6px 8px",
          textAlign: "left",
        },
      }}
    />
  );
};

export default CartProdPriceInp;
