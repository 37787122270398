import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import { Categories, Products, Actions, Cart, Checkout } from "./";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useState } from "react";
import PrintRecept from "./PrintRecept";
import { Alert, Dialog } from "../../layout";
import { useEffect } from "react";
import CashInDrawer from "./CashInDrawer";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const Pos = () => {
  const { selStore, stores, setPageTitle } = useContext(AppContext);
  const { t } = useTranslation();
  const [cartItems, setCartItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [totalPay, setTotalPay] = useState(0);
  const [tax, setTax] = useState(0);
  const [taxPct, setTaxPct] = useState(0);
  const [receptData, setReceptData] = useState({});
  const [drawerForm, setDrawerForm] = useState(false);
  const [storeDtls, setStoreDtls] = useState({});
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const navigate = useNavigate();

  const handleClearCart = () => {
    setCartItems([]);
    setSubTotal(0);
    setTotalPay(0);
    setTax(0);
    setReceptData(0);
    storeDtls ? setTaxPct(storeDtls.tax) : setTaxPct(0);
    navigate("/pos");
  };

  useEffect(() => {
    var _store = stores.find((j) => j.id === selStore);
    setStoreDtls({ ..._store });
    _store ? setTaxPct(_store.tax) : setTax(0);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    setPageTitle && setPageTitle(t("pos"));
    // Show cash in drawer form.
    if (!("posOpen" in localStorage)) setDrawerForm(true);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box className="d-none" id="printSellRecept">
        <PrintRecept data={receptData} />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} xl={7}>
          <Box>
            {/* Body */}
            <Box
              sx={{
                height: {
                  sm: "calc(100vh - 240px)",
                  xl: "calc(100vh - 215px)",
                },
                overflow: "auto",
                borderRadius: "0.8rem",
              }}
            >
              <Routes>
                <Route
                  path="checkout"
                  element={
                    <Checkout
                      {...{
                        subTotal,
                        cartItems,
                        totalPay,
                        setTotalPay,
                        setAlert,
                        tax,
                        setTax,
                        setReceptData,
                        handleClearCart,
                        taxPct,
                        setTaxPct,
                      }}
                    />
                  }
                />
                <Route path="products">
                  <Route
                    path=":catId"
                    element={
                      <Products {...{ cartItems, setCartItems, setAlert }} />
                    }
                  />
                  <Route
                    path=""
                    element={<Products {...{ cartItems, setCartItems }} />}
                  />
                </Route>
                <Route path="categories" element={<Categories />} />
                <Route
                  index
                  element={<Products {...{ cartItems, setCartItems }} />}
                />
              </Routes>
            </Box>
          </Box>
          {/* Actions */}
          <Actions
            {...{
              setReceptData,
              setAlert,
              cartItems,
              subTotal,
              tax,
              handleClearCart,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={5}>
          <Cart
            {...{
              cartItems,
              setCartItems,
              subTotal,
              setSubTotal,
              totalPay,
              setTotalPay,
              tax,
              setTax,
              taxPct,
              setTaxPct,
              storeDtls,
            }}
          />
        </Grid>
      </Grid>
      {/* Edit Dialog */}
      {drawerForm && (
        <Dialog show={drawerForm} onClose={false} maxWidth="xs">
          <CashInDrawer {...{ setShow: setDrawerForm }} />
        </Dialog>
      )}
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

export default Pos;
