import React, { useContext, useEffect, useState } from "react";
import { Alert, Table } from "../../layout";
import { Avatar, Box, IconButton, Tooltip, Button } from "@mui/material";
import { EditRounded, VisibilityRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

const Customers = (props) => {
  const { _getById } = props;
  const navigate = useNavigate();
  const { setLoader, selStore, org, setPageTitle, authUser } =
    useContext(AppContext);
  const { t } = useTranslation();
  const [tblData, setTblData] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);
  const [access, setAccess] = useState({});
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (authUser && authUser.featuresAccess && authUser.featuresAccess.customer)
      setAccess({ ...authUser.featuresAccess.customer });
    return () => {};
  }, [authUser]);

  useEffect(() => {
    setPageTitle && setPageTitle(t("customers"));
    setCsvHeader([
      { key: "cstmrCode", label: t("customerCode") },
      { key: "firstName", label: t("firstName") },
      { key: "lastName", label: t("lastName") },
      { key: "phone", label: t("phone") },
      { key: "email", label: t("email") },
      { key: "storeCredit", label: t("storeCredit") },
      { key: "hasCard", label: t("hasCard") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onComplete = (res) => {
      res && res.status === 200 && setTblData([...res.result]);
      setLoader(false);
    };

    setLoader(true);
    _getById(
      endpoints.Customer + controller.GetAllByStore,
      selStore,
      null,
      onComplete
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleAddEdit = (id) => {
    navigate(`customer-form/${id || ""}`);
  };

  const handleView = (id) => {
    navigate(`/view-customer/${id}`);
  };

  const ActionComp = (val) => (
    <div className="bs-secondary br-8 w-fit">
      {access.isHaveViewAccess && (
        <Tooltip title="View">
          <IconButton
            onClick={() => handleView(val.id)}
            size="small"
            color="primary"
            className="mr-5 p-5"
          >
            <VisibilityRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
      {access.isHaveEditAccess && (
        <Tooltip title="Edit">
          <IconButton
            onClick={() => handleAddEdit(val.id)}
            size="small"
            color="primary"
            className="mr-5 p-5"
          >
            <EditRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      {access.isHaveAddAccess && (
        <Button
          onClick={() => handleAddEdit()}
          className="btn-secondary"
          sx={{
            width: { md: "180px", xs: "100%" },
            mb: { xs: "10px", md: "0px" },
            fontSize: "17px",
            padding: "8px",
          }}
        >
          {t("addCustomer")}
        </Button>
      )}
      {access.isHaveExportAccess && (
        <Box
          className="d-flex"
          sx={{
            width: { xs: "100%", md: "auto" },
          }}
        >
          <CSVLink
            data={tblData.map((j) => ({
              ...j,
              hasCard: j.cardNumber ? t("yes") : t("no"),
            }))}
            headers={csvHeader}
            filename={`${t("customers")} (${new Date().toDateString()}).csv`}
            target="_blank"
            className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
          >
            {t("exportCSV")}
          </CSVLink>
        </Box>
      )}
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "cstmr", label: t("customer") },
          { id: "contact", label: t("contact") },
          { id: "creditAndDebit", label: t("creditAndDebit") },
          { id: "hasCard", label: t("hasCard") },
        ]}
        tblData={tblData.map((j) => {
          return {
            ...j,
            idSearch: `id ${j.id}`,
            creditAndDebit: (
              <div className="fs-14 tx-upp d-grid">
                <div className="success-color">
                  <span>{t("credit")}:&nbsp;</span>
                  <span className="fw-6">
                    {org.curSymbol + " " + j.creditBal.toFixed(org.decimal)}
                  </span>
                </div>
                <div className={j.due > 0 ? "error-color" : "gray-color"}>
                  <span>{t("debit")}:</span>
                  <span className="fw-6">
                    &nbsp;{org.curSymbol + " " + j.due.toFixed(org.decimal)}
                  </span>
                </div>
              </div>
            ),
            cstmr: (
              <div className="d-flex g-15 a-center mb-5 mt-5">
                <Avatar className="bg-primary" src={j.photo} />
                <div className="d-grid">
                  <span className="">{j.firstName + " " + j.lastName}</span>
                  <span className="fs-12">
                    <span className="fw-5">
                      {t("customerCode")}: {j.cstmrCode}
                    </span>
                    &nbsp;&nbsp; | &nbsp;&nbsp;
                    <span className="fw-5">
                      {t("id")}: {j.id}
                    </span>
                  </span>
                </div>
              </div>
            ),
            credit: (
              <span className="fs-18 fw-5">
                {org &&
                  org.curSymbol +
                    " " +
                    parseFloat(j.creditBal.toFixed(org.decimal))}
              </span>
            ),
            contact: (
              <div className="d-grid ">
                {j.email && (
                  <a
                    className="fs-14 primary-color c-pointer mb-3"
                    href={`mailto:${j.email}`}
                  >
                    ✉️ {j.email}
                  </a>
                )}
                <a
                  className="fs-14 primary-color c-pointer"
                  href={`tel:${j.phone}`}
                >
                  📞 {j.phone}
                </a>
              </div>
            ),
            hasCard: (
              <span className="fw-5">{j.cardNumber ? t("yes") : t("no")}</span>
            ),
          };
        })}
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Customers);
