import React, { useEffect } from "react";
import { InputCheck } from "../../../controls";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";

const InventoryAccess = ({ setFeaturesAccess, featuresAccess }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!featuresAccess.id) {
      featuresAccess.isHaveInventoryAccess
        ? setFeaturesAccess((j) => ({
            ...j,
            inventory: {
              isHaveAddAccess: true,
              isHaveViewAccess: true,
              isHaveExportAccess: true,
              isHaveDeleteAccess: true,
            },
          }))
        : setFeaturesAccess((j) => ({
            ...j,
            inventory: {
              isHaveAddAccess: false,
              isHaveViewAccess: false,
              isHaveExportAccess: false,
              isHaveDeleteAccess: false,
            },
          }));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresAccess.isHaveInventoryAccess]);

  const handleInpChg = (e) => {
    const { name, checked } = e.target;
    setFeaturesAccess((j) => ({
      ...j,
      inventory: { ...j.inventory, [name]: checked },
    }));
  };

  return (
    <Collapse in={featuresAccess.isHaveInventoryAccess || false}>
      <div className="ml-25 d-grid g-5 mt-5">
        <InputCheck
          label={t("isHaveAddAccess")}
          checked={
            (featuresAccess.inventory &&
              featuresAccess.inventory.isHaveAddAccess) ||
            false
          }
          name={"isHaveAddAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveViewAccess")}
          checked={
            (featuresAccess.inventory &&
              featuresAccess.inventory.isHaveViewAccess) ||
            false
          }
          name={"isHaveViewAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveExportAccess")}
          checked={
            (featuresAccess.inventory &&
              featuresAccess.inventory.isHaveExportAccess) ||
            false
          }
          name={"isHaveExportAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveDeleteAccess")}
          checked={
            (featuresAccess.inventory &&
              featuresAccess.inventory.isHaveDeleteAccess) ||
            false
          }
          name={"isHaveDeleteAccess"}
          onClick={handleInpChg}
        />
      </div>
    </Collapse>
  );
};

export default InventoryAccess;
