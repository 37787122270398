import axios from "axios";

// const BaseUrl = "http://localhost:7084/api/";
const BaseUrl = "https://devbackend.jhprog.com/api/";

export const controller = {
  User: "User",
  Auth: "Auth",
  Org: "Org",
  Outlet: "Outlet",
  Customer: "Customer",
  Category: "Category",
  Supplier: "Supplier",
  Brand: "Brand",
  Tag: "Tag",
  Product: "Product",
  Sell: "Sell",
  Inventory: "Inventory",
  PosItem: "PosItem",
  ActivityLog: "ActivityLog",
  PayMethod: "PayMethod",
  Currency: "Currency",
  Report: "Report",
};

export const action = {
  Search: "/Search",
  GetAll: "/GetAll",
  GetById: "/GetById",
  Post: "/Post",
  Put: "/Put",
  Delete: "/Delete",
  GetAuthUser: "/GetAuthUser",
  Login: "/Login",
  Logout: "/Logout",
  Forgot: "/Forgot",
  ChgPass: "/ChgPass",
  GetAllByStore: "/GetAllByStore",
  GetByStoreId: "/GetByStoreId",
  GetAllByOrg: "/GetAllByOrg",
  GetProdsByCat: "/GetProdsByCat",
  GetDefaultPosItems: "/GetDefaultPosItems",
  FindProducts: "/FindProducts",
  GetCatsByStore: "/GetCatsByStore",
  GetPosItems: "/GetPosItems",
  ChangeActiveStatus: "/ChangeActiveStatus",
  CheckUserName: "/CheckUserName",
  SellByEmployee: "/SellByEmployee",
  StoreReport: "/StoreReport",
  ProductsReport: "/ProductsReport",
  SellVolumes: "/SellVolumes",
  TopSellingProducts: "/TopSellingProducts",
};

export const Server = (endpoint) => {
  const url = BaseUrl + endpoint + "/";
  const get = (onComplete) =>
    axios
      .get(url, { withCredentials: true })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });

  const getById = (id, onComplete) =>
    axios
      .get(url + id, { withCredentials: true })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });

  const getByIdAngPg = (id, pageNumber, rowsPerPage, onComplete) =>
    axios
      .get(url, {
        params: { id: id, pageNumber: pageNumber, rowsPerPage: rowsPerPage },
        withCredentials: true,
      })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });

  const post = (data, onComplete) =>
    axios
      .post(url, data, { withCredentials: true })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });

  const put = (data, onComplete) =>
    axios
      .put(url, data, { withCredentials: true })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });

  const Delete = (id, onComplete) =>
    axios
      .delete(url + id, { withCredentials: true })
      .then((res) => onComplete && onComplete(res.data))
      .catch((err) => {
        onComplete && onComplete(undefined, err);
        console.error("Error: ", err);
      });
  return {
    get,
    getById,
    post,
    put,
    Delete,
    getByIdAngPg,
  };
};
