import {
  Autocomplete,
  Chip,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

export default function MultiSelect(props) {
  const {
    label,
    name,
    value,
    error,
    onChange,
    variant,
    options = [],
    ...other
  } = props;
  const [val, setVal] = useState([]);

  useEffect(() => {
    setVal([...options.filter((j) => value.some((y) => y === j.id))]);
  }, [options, value]);

  const handleChange = (even, newVal) => {
    var _val = newVal ? newVal.map((j) => j.id) : [];
    var e = {
      target: {
        value: _val,
        name: name,
      },
    };
    onChange(e);
  };

  const handleInpChg = (even, newVal) => {
    !newVal && handleChange(null, newVal);
  };

  return (
    <>
      <Autocomplete
        multiple
        options={options}
        autoHighlight
        isOptionEqualToValue={(opt, val) => opt.id === val.id}
        getOptionLabel={(opt) => (opt ? opt.label : "")}
        value={val || []}
        onChange={handleChange}
        onInputChange={handleInpChg}
        filterSelectedOptions
        {...other}
        sx={{
          "& .MuiFilledInput-root::before": {
            borderBottom: `1px solid var(--grayMD-color)`,
          },
        }}
        renderOption={(prop, opt) => (
          <ListItemButton {...prop} sx={{ height: "35px" }}>
            <ListItemText primary={opt.label} secondary={opt.subtitle} />
          </ListItemButton>
        )}
        renderTags={(j, getTagProps) =>
          j.map((option, index) => (
            <Chip
              sx={{ mt: "5px !important" }}
              label={option && option.label}
              {...getTagProps({ index })}
            />
          ))
        }
        componentsProps={{
          paper: {
            sx: {
              borderRadius: "10px",
              backgroundColor: "var(--white-color)",
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            variant={variant || "outlined"}
            label={label}
            {...params}
            {...(error && { error: true, helperText: error })}
          />
        )}
      />
    </>
  );
}
