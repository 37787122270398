import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Input from "./Input";

const InputDate = (props) => {
  const {
    label,
    variant = "outlined",
    name,
    value,
    onChange,
    error,
    minDate,
    maxDate,
    disabled = false,
    openTo = "day",
    fullWidth = true,
    required = false,
    views = ["day", "month", "year"],
    ...other
  } = props;

  return !disabled ? (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        name={name}
        value={(value && dayjs(value)) || null}
        onChange={onChange}
        views={views}
        openTo={openTo}
        minDate={(minDate && dayjs(minDate)) || null}
        maxDate={(maxDate && dayjs(maxDate)) || null}
        sx={{
          "& .MuiFilledInput-root::before": {
            borderBottom: `1px solid var(--grayMD-color)`,
          },
        }}
        slotProps={{
          textField: {
            variant: variant,
            fullWidth: fullWidth,
            ...other,
            ...(error && { error: true, helperText: error }),
            required: required,
          },

          desktopPaper: {
            style: {
              backgroundColor: "var(--white-color)",
              boxShadow: "none",
              border: "1px solid var(--primaryMD-color)",
            },
          },
          mobilePaper: {
            style: {
              backgroundColor: "var(--white-color)",
            },
          },
        }}
      />
    </LocalizationProvider>
  ) : (
    <Input label={label} name={name} value={value} disabled />
  );
};

export default InputDate;
