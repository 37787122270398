import { Grid, Paper } from "@mui/material";
import React, { useContext, useState } from "react";
import {
  Button,
  Input,
  InputAuto,
  InputDate,
  LabelComp,
} from "../../../controls";
import { Alert, Form } from "../../../layout";
import { DropLi, Model, UseForm } from "../../../hooks";
import * as actions from "../../../api/actions/pos";
import { connect } from "react-redux";
import { useEffect } from "react";
import { endpoints } from "../../../api/actions/api";
import { MaxLength, RmvEmptyProp, ToFormData } from "../../../hooks/Method";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SalaryPayment = (props) => {
  const { _post, _get, _clear } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { PaymentMdl } = Model();
  const { selStore, setPageTitle } = useContext(AppContext);
  const [searchLi, setSearchLi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const { Gender, Periodic } = DropLi();
  const [err, setErr] = useState();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const {
    values,
    errors,
    setErrors,
    handleInpChg,
    handleInpDate,
    resetFormControls,
  } = UseForm(PaymentMdl);

  const validateForm = () => {
    let temp = {};
    temp.fromDate = values.fromDate !== "" ? "" : t("fromDateIsRequired");
    temp.toDate = values.toDate !== "" ? "" : t("toDateIsRequired");
    temp.totalSalary =
      values.totalSalary !== "" ? "" : t("totalSalaryIsRequired");
    temp.payDate = values.payDate !== "" ? "" : t("payDateIsRequired");
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm() && user.id) {
      setLoading(true);
      values.userId = user.id;
      values.storeId = selStore;
      const onComplete = (res) => {
        setLoading(false);
        if (res.status === 201) {
          setAlert({
            isOpen: true,
            type: "success",
            title: t("success"),
            subTitle: res.msg,
            onClick: () => navigate("/salary-payments"),
          });
          resetFormControls();
          setUser({});
        } else {
          setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: t("warningMsg"),
          });
        }
      };
      _post(
        endpoints.Salary,
        ToFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    } else if (!user.id) {
      setErr(t("userNotSelectedError"));
    }
  };

  const handleSelect = (e) => {
    var userDtls = searchLi.find((j) => j.id === e.target.value);
    userDtls ? setUser(userDtls) : setUser({});
    userDtls && setErr();
  };

  useEffect(() => {
    setPageTitle && setPageTitle("Salary Pay Form");
    // Get Users
    const onComplete = (res) => {
      res.status === 200 &&
        setSearchLi([
          ...res.result.map((j) => {
            return {
              ...j,
              id: j.id,
              label: `${j.firstName} ${j.lastName} (${j.phone})`,
            };
          }),
        ]);
    };

    _get(endpoints.User, null, onComplete);

    return () => {
      _clear();
      _clear({}, actions.ActionType.ClearData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="d-flex j-between a-center p-10">
          <div>
            <span className="border-left fs-22 tx-upp fw-5 gray-color pl-10 mb-20">
              {t("salaryPaymentForm")}
            </span>
          </div>

          <Button
            loading={loading}
            onClick={handleSubmit}
            sx={{ minWidth: "150px" }}
            size="large"
          >
            {t("submit")}
          </Button>
        </div>

        {/* Search Employee */}
        <div className="mt-10 mb-10" />
        <InputAuto
          label={t("searchEmployeeByNameOrPhone")}
          options={searchLi || []}
          onChange={handleSelect}
          error={err}
        />

        {/* Employee Info. */}
        <Paper className="mt-30 shadow-xs br-8">
          <Grid container spacing={3} className="p-15 pt-0">
            <Grid item xs={12}>
              <span className="fs-18 fw-5 gray-color pl-10 border-left tx-upp">
                {t("userInformation")}
              </span>
            </Grid>
            <Grid item xs={12} md={6}>
              <LabelComp label={t("firstName")} value={user.firstName} />
            </Grid>
            <Grid item xs={12} md={6}>
              <LabelComp label={t("lastName")} value={user.lastName} />
            </Grid>
            <Grid item xs={12} md={4}>
              <LabelComp label={t("dateOfBirth")} value={user.dob} />
            </Grid>
            <Grid item xs={12} md={4}>
              <LabelComp
                label={t("gender")}
                value={
                  user.gender && Gender.find((j) => j.id === user.gender).name
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <LabelComp label={t("phone")} value={user.phone} />
            </Grid>
            <Grid item xs={12}>
              <LabelComp label={t("address")} value={user.address} />
            </Grid>
            <Grid item xs={12} md={6}>
              <LabelComp
                label={t("salaryType")}
                value={
                  user.salaryType &&
                  Periodic.find((j) => j.id === user.salaryType).name
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LabelComp label={t("salary")} value={user.salary} />
            </Grid>
          </Grid>
        </Paper>

        {/* Pay Period */}
        <Paper className="mt-30 shadow-xs br-8">
          <Grid container spacing={3} className="p-15 pt-0">
            <Grid item xs={12}>
              <span className="fs-18 fw-5 gray-color pl-10 border-left tx-upp">
                {t("payPeriod")}
              </span>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputDate
                required
                label={t("fromDate")}
                name="fromDate"
                value={values.fromDate}
                error={errors.fromDate}
                onChange={(date) =>
                  handleInpDate("fromDate", new Date(date).toLocaleDateString())
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputDate
                required
                label={t("toDate")}
                name="toDate"
                value={values.toDate}
                error={errors.toDate}
                onChange={(date) =>
                  handleInpDate("toDate", new Date(date).toLocaleDateString())
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper className="mt-30 shadow-xs br-8 mb-20">
          <Grid container spacing={3} className="p-10 pt-0">
            <Grid item xs={12} md={4}>
              <Input
                label={t("totalHours")}
                name="totalHours"
                type="number"
                onInput={(e) => (e.target.value = MaxLength(e.target.value, 9))}
                value={values.totalHours}
                error={errors.totalHours}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                required
                label={t("totalSalary")}
                name="totalSalary"
                type="number"
                onInput={(e) => (e.target.value = MaxLength(e.target.value, 9))}
                value={values.totalSalary}
                error={errors.totalSalary}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputDate
                required
                label={t("payDate")}
                name="payDate"
                value={values.payDate}
                error={errors.payDate}
                onChange={(date) =>
                  handleInpDate("payDate", new Date(date).toLocaleDateString())
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
      </Form>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _data: state.pos.data,
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
  _get: actions.get,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(SalaryPayment);
