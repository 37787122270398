import { Divider, Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Form, SimpleTable, Table } from "../../layout";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { useTranslation } from "react-i18next";
import { Button, Input, Select } from "../../controls";
import Label from "./Label";
import { AppContext } from "../../App";
import { DropLi, Model, UseForm } from "../../hooks";
import { NestedFormData, PrintDiv, RmvArryEmptyProp } from "../../hooks/Method";
import InputSwitch from "../../controls/InputSwitch";
import SellReturnReceipt from "./SellReturnReceipt";

const ReturnForm = (props) => {
  const { _getById, _post, _authUser } = props;
  const { id } = useParams();
  const { org, selStore, setPageTitle } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState();
  const { ReturnProdMdl } = Model();
  const { ReturnReason } = DropLi();
  const [reasons, setReasons] = useState([]);
  const { values, setValues } = UseForm(ReturnProdMdl);
  const [loading, setLoading] = useState(false);
  const [receiptData, setReceiptData] = useState({});
  const [showMultPay, setShowMultPay] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setPageTitle(t("sellReturnForm"));

    if (id && selStore && org && org.id && _authUser && _authUser.id) {
      _getById(endpoints.Sell + controller.GetById, id, null, (res) => {
        setLoading(false);
        if (res.status === 200) {
          setInvoice(res.result);
          setValues((j) => ({
            ...j,
            sellId: res.result.id,
            storeId: selStore,
            orgId: org.id,
            userId: _authUser.id,
            items: res.result.sellItems.map((j, i) => ({
              ...j,
              key: i + 1,
              id: "",
              isSellable: true,
              sellItemId: j.id,
              returnQty: 0,
              returnReason: 1,
              subtotal: 0,
              userId: _authUser.id,
            })),
          }));
        }
      });
    }

    setReasons([...ReturnReason.filter((j) => j.id !== 6)]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selStore, org, _authUser]);

  useEffect(() => {
    if (invoice && values) {
      // Calculate refund amount.
      var _refundAmt = values.items
        .map((item) => parseFloat(item.returnQty) * item.price)
        .reduce((x, y) => x + y, 0);

      // Calculate discount amount.
      var _disAmt = 0;
      if (invoice.discountType === 1)
        _disAmt = _refundAmt * (invoice.discount / 100);
      else _disAmt = invoice.discount;

      // Calculate tax amount.
      var _taxAmt = _refundAmt * (invoice.taxPct / 100);

      setValues((j) => ({
        ...j,
        refundAmt: _refundAmt.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        }),
        cutDiscount: _disAmt.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        }),
        refundTaxAmt: _taxAmt.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        }),
        payableRefund: (_refundAmt - _disAmt + _taxAmt).toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 2,
          }
        ),
      }));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.items]);

  const handleMultPayments = () => {
    setShowMultPay(!showMultPay);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    _post(
      endpoints.SellReturn + controller.Post,
      NestedFormData(
        RmvArryEmptyProp({
          ...values,
          items: values.items.filter((j) => parseFloat(j.returnQty) > 0),
        })
      ),
      null,
      (res) => {
        setReceiptData(res.result);

        setTimeout(() => {
          setLoading(false);
          PrintDiv("printSellReturnRecept");
          navigate("/pos");
        }, 1500);
      }
    );
  };

  const handleInpChg = (param) => {
    setValues((val) => ({
      ...val,
      items: val.items.map((j) =>
        j.key === param.key ? { ...j, [param.name]: param.value } : j
      ),
    }));
  };

  return invoice && org && values.sellId ? (
    <>
      <div className="d-none" id="printSellReturnRecept">
        <SellReturnReceipt data={receiptData} />
      </div>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="d-grid g-20">
          {/* ====== Details ====== */}
          <Paper>
            <div className="p-10 d-flex j-between a-center">
              <div>
                <span className="fs-18 tx-upp gray-color fw-5 border-left">
                  {t("sellReturnForm")}
                </span>
              </div>
              <div>
                <Button
                  type="submit"
                  loading={loading}
                  sx={{ minWidth: "100px" }}
                >
                  {t("submit")}
                </Button>
              </div>
            </div>
            <Divider />
            <Grid container spacing={3} className="p-15">
              <Label name={t("invoiceID")} value={invoice.id} />
              <Label name={t("date")} value={invoice.sellDate} />
              <Label
                name={t("businessLocation")}
                value={
                  invoice.store &&
                  invoice.store.storeName + " | " + invoice.store.address
                }
              />

              <Label
                name={t("name")}
                value={
                  invoice.cstmr
                    ? invoice.cstmr.firstName + " " + invoice.cstmr.lastName
                    : "Walk-In Customer"
                }
              />
              {invoice.cstmr && (
                <>
                  <Label name={t("customerId")} value={invoice.cstmrId} />
                  <Label
                    name={t("phone")}
                    value={invoice.cstmr && invoice.cstmr.phone}
                  />
                </>
              )}
            </Grid>

            <Divider />
            {/* ===== Accounts ===== */}
            <Grid container>
              <Grid item xs={12} md={6}>
                <SimpleTable
                  list={[
                    {
                      label: t("subtotal"),
                      value: `${
                        org.curSymbol
                      } ${invoice.subtotal.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}`,
                    },
                    {
                      label: `${t("tax")} (${invoice.taxPct}%)`,
                      value: `(+) ${org.curSymbol} ${invoice.tax.toLocaleString(
                        "en-US",
                        { maximumFractionDigits: 2 }
                      )}`,
                    },
                    {
                      label: `${t("discount")} ${
                        invoice.discountType === 1 && `(${invoice.discount}%)`
                      }`,
                      value: `(-) ${
                        org.curSymbol
                      } ${invoice.disAmt.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}`,
                    },
                    {
                      disable: !(invoice.dueAmt > 0),
                      label: t("due"),
                      value: (
                        <span>
                          {org.curSymbol}{" "}
                          {invoice.dueAmt.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      ),
                    },
                    {
                      disable: !(invoice.creditAmt > 0),
                      label: t("usedCredit"),
                      value: `${
                        org.curSymbol
                      } ${invoice.creditAmt.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}`,
                    },

                    {
                      disable: !org.enableMultCurr,
                      label: t("multiPayments"),
                      value:
                        invoice.multPays.length > 0 ? (
                          <span
                            onClick={handleMultPayments}
                            className="primary-color tx-und c-pointer"
                          >
                            {org.curSymbol}{" "}
                            {invoice.multiPayAmt.toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        ) : (
                          "0"
                        ),
                    },
                    {
                      label: t("totalPay"),
                      value: `${
                        org.curSymbol
                      } ${invoice.totalPay.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}`,
                    },
                    {
                      label: t("paymentReceived"),
                      value: `${
                        org.curSymbol
                      } ${invoice.received.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}`,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SimpleTable
                  list={[
                    {
                      label: t("refund"),
                      value: (
                        <span>
                          {org.curSymbol}{" "}
                          {values.refundAmt.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      ),
                    },
                    {
                      label: t("minusDiscountAmount"),
                      value: (
                        <span>
                          {"(-) "}
                          {org.curSymbol}{" "}
                          {values.cutDiscount.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      ),
                    },
                    {
                      label: t("refundTaxAmt"),
                      value: (
                        <span>
                          {"(+) "}
                          {org.curSymbol}{" "}
                          {values.refundTaxAmt.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      ),
                    },
                    {
                      label: (
                        <span className="error-color">
                          {t("payableRefund")}
                        </span>
                      ),
                      value: (
                        <span className="error-color">
                          {org.curSymbol}{" "}
                          {values.payableRefund.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      ),
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Paper>
          {/* ====== Product List ======  */}
          <>
            <Table
              ActionComp={() => <></>}
              HeaderComp={() => <></>}
              showHeader={false}
              showSearch={false}
              tblHead={[
                { id: "serial", label: "#" },
                { id: "prod", label: t("productDetails") },
                { id: "sellable", label: t("isSellable") + " ?" },
                { id: "returnQty", label: t("returnQuantity") },
                { id: "reason", label: t("returnReason") },
                { id: "subtotal", label: t("returnSubtotal") },
              ]}
              tblData={values.items.map((item, ind) => {
                return {
                  ...item,
                  serial: ind + 1,
                  sellable: (
                    <>
                      <InputSwitch
                        size="small"
                        className="gray-color"
                        onChange={(e) =>
                          handleInpChg({
                            name: "isSellable",
                            key: item.key,
                            value: e.target.checked,
                          })
                        }
                        checked={item.isSellable}
                        name="isSellable"
                      />
                    </>
                  ),
                  prod: (
                    <div className="d-grid">
                      <span>{item.product.name}</span>
                      <div className="fs-12 d-flex g-5 fw-5">
                        <span>
                          {t("price")}:&nbsp;
                          {org.curSymbol +
                            " " +
                            item.price.toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                            })}
                        </span>{" "}
                        |{" "}
                        <span>
                          {t("qty")}:&nbsp;
                          {item.qty.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    </div>
                  ),

                  returnQty: (
                    <>
                      <Input
                        placeholder={t("returnQuantity")}
                        name="returnQty"
                        type="number"
                        size="small"
                        variant="outlined"
                        value={item.returnQty}
                        onInput={(e) =>
                          e.target.value > 0 ? e : (e.target.value = "")
                        }
                        onChange={(e) =>
                          (parseFloat(e.target.value) <= item.qty ||
                            item.returnQty.length > e.target.value.length) &&
                          handleInpChg({
                            key: item.key,
                            name: "returnQty",
                            value: e.target.value,
                          })
                        }
                        InputProps={{
                          onFocus: (event) => event.target.select(),
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            padding: "0px 0px",
                            maxWidth: "150px",
                          },
                          "& .MuiInputBase-input": {
                            padding: "5px 10px",
                          },
                        }}
                      />
                    </>
                  ),
                  reason: (
                    <Select
                      variant="outlined"
                      placeholder={t("selectReason")}
                      name="returnReason"
                      size="small"
                      fullWidth
                      value={item.returnReason}
                      onChange={(e) =>
                        handleInpChg({
                          key: item.key,
                          name: "returnReason",
                          value: e.target.value,
                        })
                      }
                      options={reasons}
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "0px 10px",
                        },
                        "& .MuiInputBase-input": {
                          padding: "5.5px 0px",
                          textAlign: "center",
                        },
                      }}
                    />
                  ),
                  subtotal: `${org.curSymbol} ${(
                    parseFloat(item.returnQty) * item.price
                  ).toLocaleString("en-US", { maximumFractionDigits: 2 })}`,
                };
              })}
            />
          </>
        </div>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  ) : (
    "Loading..."
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(ReturnForm);
