import React, { useContext, useState } from "react";
import { Table } from "../../layout";
import { AppContext } from "../../App";
import { useEffect } from "react";
import { action, controller, Server } from "../../api/Server";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CSVLink } from "react-csv";
import { ChangeArrayKey } from "../../hooks/Method";

const ProdSellVol = () => {
  const { setLoader, selStore, org, setPageTitle } = useContext(AppContext);
  const [sells, setSells] = useState([]);
  const { t } = useTranslation();
  const [csvHeader, setCsvHeader] = useState([]);

  useEffect(() => {
    if (selStore && org) {
      setLoader(true);
      Server(controller.Report + action.SellVolumes).getById(
        selStore,
        (res) => {
          setLoader(false);
          res &&
            res.status === 200 &&
            setSells([
              ...res.result.map((j) => ({
                ...j,
                currentMonthSale:
                  org.curSymbol + " " + j.currentMonthSale.toFixed(org.decimal),
                lastMonthSale:
                  org.curSymbol + " " + j.lastMonthSale.toFixed(org.decimal),
                status:
                  j.currentMonthSale > j.lastMonthSale ? t("high") : t("low"),
              })),
            ]);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore, org]);

  useEffect(() => {
    setPageTitle(t("productsSellVolume"));
    setCsvHeader([
      { key: "productName", label: t("productName") },
      { key: "numberOfItemSold", label: t("numberOfItemSold") },
      { key: "lastMonthSale", label: t("lastMonthSale") },
      { key: "currentMonthSale", label: t("currentMonthSale") },
      { key: "status", label: t("status") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={sells}
          headers={csvHeader}
          filename={`${t(
            "productsSellVolume"
          )} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={HeaderComp}
        tblHead={[...ChangeArrayKey(csvHeader, "key", "id")]}
        tblData={sells}
      />
    </>
  );
};

export default ProdSellVol;
