import React, { useContext, useEffect, useState } from "react";
import { Divider, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { useParams } from "react-router-dom";
import { action, controller, Server } from "../../api/Server";
import SearchProd from "./SearchProd";

const Products = ({ cartItems, setCartItems }) => {
  const { t } = useTranslation();
  const { org, selStore } = useContext(AppContext);
  const { catId } = useParams();
  const [prods, setProds] = useState([]);

  const prodCls = {
    borderTopLeftRadius: "0.8rem",
    borderTopRightRadius: "0.8rem",
    height: "150px",
    width: "100%",
    objectFit: "cover",
    borderBottom: "1px solid var(--grayLight-color)",
  };

  useEffect(() => {
    getDefaultPosItems(catId);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore, catId]);

  const getDefaultPosItems = (categoryId) => {
    if (selStore) {
      const onComplete = (res) => {
        if (res && res.status === 200) setProds([...res.result]);
        else {
          setProds([]);
          res && console.error("POS Products getting error: ", res.msg);
        }
      };

      categoryId // Get Products by category
        ? Server(controller.Inventory + action.GetProdsByCat).post(
            {
              storeId: selStore,
              catId: categoryId,
            },
            onComplete
          )
        : // Get default products
          Server(controller.PosItem + action.GetDefaultPosItems).getById(
            selStore,
            onComplete
          );
    }
  };

  const handleCart = (prod) => {
    const cartVal = cartItems.find((j) => j.productId === prod.product.id);
    if (
      prod.inInventory > 0 &&
      (cartVal === undefined || prod.inInventory > cartVal.qty)
    ) {
      if (cartVal) {
        setCartItems((x) => [
          ...x.map((j) =>
            j.productId === prod.product.id
              ? { ...j, qty: parseInt(j.qty) + 1 }
              : j
          ),
        ]);
      } else {
        var priceDtl =
          prod.product &&
          prod.product.sellPrices.length > 0 &&
          prod.product.sellPrices[0];
        setCartItems((x) => [
          {
            productId: prod.product.id,
            name: prod.product.name,
            price: priceDtl ? priceDtl.price : 0,
            priceId: priceDtl ? priceDtl.id : "",
            prices: prod.product
              ? prod.product.sellPrices.map((j) => ({
                  ...j,
                  label: `${org.curSymbol} ${j.price.toFixed(3)}`,
                  subtitle: j.title,
                }))
              : [],
            tax: prod.product.tax,
            qty: 1,
            itemType: 1,
          },
          ...x,
        ]);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SearchProd {...{ setProds, handleCart, getDefaultPosItems }} />
      </Grid>
      {prods.map((inv, i) => (
        <Grid item xs={6} sm={3} xl={2} key={i}>
          <Paper
            onClick={() => handleCart(inv)}
            className="br-8 shadow-xs c-pointer"
          >
            <img
              style={prodCls}
              className="img-fluid obj-contain"
              src={
                inv.product.imgs.length > 0
                  ? inv.product.imgs[0].imgUrl
                  : `${process.env.PUBLIC_URL}/assets/no-pictures.png`
              }
              alt="prod-img"
            />
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "12px" }}
              className="p-10 gray-color"
            >
              {inv.product.name}
              <Divider />
              <div className="d-grid">
                <span className="fs-12 fw-6">
                  {t("price")}:{" "}
                  {(org && org.curSymbol) +
                    " " +
                    (inv.product.sellPrices[0]
                      ? inv.product.sellPrices[0].price.toFixed(3)
                      : "null")}
                </span>

                <span
                  className={`fs-12 fw-6 ${
                    inv.inInventory <= 0 && " error-color"
                  }`}
                >
                  {t("stock")}: {inv.inInventory}
                </span>
              </div>
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Products;
