import React, { useContext, useState } from "react";
import { Button } from "../../../../controls";
import Payments from "./Payments";
import { Dialog } from "../../../../layout";
import PaymentForm from "./PaymentForm";
import { AppContext } from "../../../../App";
import { useTranslation } from "react-i18next";

const PaymentReceived = ({ needToPayAmt, values, setValues }) => {
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const [showForm, setShowForm] = useState(false);

  const handleAddPayment = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <div className="d-flex g-15 a-center j-between bg-white p-5 br-12">
        <div>
          <span className="fs-22 tx-upp gray-color fw-5 ml-5">
            {t("paymentReceived")}
          </span>
        </div>

        <div className="d-flex g-10 a-center">
          <span className="fs-24 fw-5 gray-color mr-10">
            {org && org.curSymbol}{" "}
            {parseFloat(values.received || 0).toFixed(org.decimal)}
          </span>
          {needToPayAmt > 0 && (
            <Button onClick={handleAddPayment}>{t("addPayment")}</Button>
          )}
        </div>
      </div>

      {/* Payments */}
      {values.payments && values.payments.length > 0 && (
        <div className="mt-20">
          <Payments {...{ values, setValues, org }} />
        </div>
      )}
      {/* Edit Dialog */}
      {showForm && (
        <Dialog show={showForm} onClose={false}>
          <PaymentForm
            {...{ setSellValues: setValues, setShowForm, needToPayAmt }}
          />
        </Dialog>
      )}
    </>
  );
};

export default PaymentReceived;
