import { Button, Container, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { connect } from "react-redux";
import { InputRadio } from "../../controls";
import * as actions from "../../api/actions/pos";
import { useEffect } from "react";
import { Alert, Form } from "../../layout";
import { controller, endpoints } from "../../api/actions/api";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const DefaultStore = (props) => {
  const { _getById } = props;
  const { t } = useTranslation();
  const [storeLi, setStoreLi] = useState([]);
  const { setLoader, stores, setPageTitle } = useContext(AppContext);
  const [value, setValue] = useState(0);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setPageTitle && setPageTitle(t("defaultStore"));
    // Set Store list in the state.
    if (stores.length > 0) {
      setStoreLi([
        ...stores.map((j) => {
          return {
            id: j.id,
            name: j.storeName,
          };
        }),
      ]);

      var defStore = stores.find((j) => j.defStore);
      setValue(defStore ? defStore.id : stores[0]);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores]);

  const handleInpChange = (e) => {
    setValue(parseInt(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200
        ? setAlert({
            isOpen: true,
            type: "success",
            title: t("success"),
            subTitle: t("successfullySetAsADefaultStore"),
          })
        : setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: t("somethingIsWrongPleaseTryAgain"),
          });
    };

    _getById(
      endpoints.Store + controller.SetDefStore,
      value,
      actions.ActionType.DefStore,
      onComplete
    );
  };

  return (
    <Container maxWidth="sm">
      <Form noValidate onSubmit={handleSubmit}>
        <Paper className="br-8 shadow-xs p-20">
          <Box className="tx-center mb-20">
            <Typography variant="subtitle1" className="fs-22 fw-5 gray-color">
              {t("myStores")}
            </Typography>
            <div className="bs-bottom mx-w-60 m-auto" />
          </Box>
          <Box sx={{ ml: { md: "80px" } }}>
            <InputRadio
              options={storeLi}
              value={value}
              onChange={handleInpChange}
            />
          </Box>
          <Box className="tx-center mt-30">
            <Button type="submit" className="btn-secondary" size="large">
              {t("submitToDefaultStore")}
            </Button>
          </Box>
        </Paper>
      </Form>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _stores: state.pos.stores,
  _data: state.pos.data,
});

const mapActiontoProps = {
  _get: actions.get,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActiontoProps)(DefaultStore);
