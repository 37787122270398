import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Input, InputAuto, Select } from "../../controls";
import { useTranslation } from "react-i18next";
import { action, controller, Server } from "../../api/Server";
import { DropLi } from "../../hooks";

const GeneralFields = ({ values, errors, org, handleInpChg }) => {
  const { t } = useTranslation();
  const { Decimals } = DropLi();
  const [currs, setCurrs] = useState([]);
  const [methods, setMethods] = useState([]);

  useEffect(() => {
    if (org && org.id) {
      // Get Currencies
      Server(controller.Currency + action.GetAllByOrg).getById(org.id, (res) =>
        setCurrs([
          ...res.result.map((j) => ({
            ...j,
            label: `${j.currency} (${j.symbol}) - ${j.name}`,
          })),
        ])
      );

      // Get PayMethods
      Server(controller.PayMethod + action.GetAllByOrg).getById(
        org.id,
        (res) => {
          if (res && res.status === 200) {
            var result = res.result;
            setMethods([...result.map((j) => ({ ...j, label: j.title }))]);
          }
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Input
          label={t("organizationName")}
          name="name"
          required={true}
          value={values.name}
          error={errors.name}
          onChange={handleInpChg}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Input
          label={t("localeOrgName")}
          name="nameLocale"
          value={values.nameLocale}
          error={errors.nameLocale}
          onChange={handleInpChg}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("email")}
          name="email"
          required={true}
          value={values.email}
          error={errors.email}
          onChange={handleInpChg}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("phone")}
          name="phone"
          required={true}
          value={values.phone}
          error={errors.phone}
          onChange={handleInpChg}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <InputAuto
          label={t("currency")}
          name="currId"
          required={true}
          value={values.currId}
          error={errors.currId}
          onChange={handleInpChg}
          options={currs}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Select
          label={t("allowDecimal")}
          name="decimal"
          required={true}
          value={values.decimal}
          error={errors.decimal}
          onChange={handleInpChg}
          options={Decimals}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Select
          label={t("defaultPaymentMethod")}
          name="defaultPayMethodId"
          required={true}
          value={values.defaultPayMethodId}
          error={errors.defaultPayMethodId}
          onChange={handleInpChg}
          options={methods}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralFields;
