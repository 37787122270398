import React, { useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Button, InputDate } from "../../controls";
import { Box } from "@mui/system";
import { AttachMoneyRounded } from "@mui/icons-material";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { action, controller, Server } from "../../api/Server";
import { subDays } from "date-fns";

const StoreReports = () => {
  const { t } = useTranslation();
  const { setLoader, selStore, org, setPageTitle } = useContext(AppContext);
  const [expenses, setExpenses] = useState([]);
  const [accounts, setAccounts] = useState();
  const [sortDate, setSortDate] = useState({
    fromDate: moment().startOf("month").format("MM/DD/YYYY"),
    toDate: moment().endOf("month").format("MM/DD/YYYY"),
  });

  const handleInpChange = (name, newVal) => {
    setSortDate((x) => ({ ...x, [name]: newVal }));
  };

  const onComplete = (res) => {
    setLoader(false);
    if (res && res.status === 200) {
      setAccounts(res.result.accounts);
      setExpenses([...res.result.expenses]);
    }
  };

  const GetDataFromServer = () => {
    if (selStore && sortDate) {
      setLoader(true);
      Server(controller.Report + action.StoreReport).post(
        {
          storeId: selStore,
          fromDate: sortDate.fromDate,
          toDate: sortDate.toDate,
        },
        onComplete
      );
    }
  };

  useEffect(() => {
    setPageTitle(t("storeReport"));
    GetDataFromServer();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <>
      {accounts && org ? (
        <Grid container spacing={3}>
          {/* Search  */}
          <Grid item xs={12}>
            <Paper className="shadow-xs p-10 d-flex a-center j-between br-9">
              <div>
                <span className="fs-22 border-left tx-upp fw-5 gray-color">
                  {t("storeReport")}
                </span>
              </div>
              <div className="d-flex a-center g-10">
                <InputDate
                  size="small"
                  variant="outlined"
                  sx={{ maxWidth: "150px" }}
                  name="fromDate"
                  value={sortDate.fromDate}
                  views={["day", "month", "year"]}
                  openTo="day"
                  mindate={new Date("01/01/2000")}
                  maxdate={subDays(new Date(sortDate.toDate), 1)}
                  onChange={(date) =>
                    handleInpChange(
                      "fromDate",
                      new Date(date).toLocaleDateString()
                    )
                  }
                />
                <span className="fs-16 dark-color">TO</span>
                <InputDate
                  size="small"
                  variant="outlined"
                  sx={{ maxWidth: "150px" }}
                  name="toDate"
                  value={sortDate.toDate}
                  mindate={moment(sortDate.fromDate, "MM/DD/YYYY").add(
                    1,
                    "days"
                  )}
                  views={["day", "month", "year"]}
                  openTo="day"
                  onChange={(date) =>
                    handleInpChange(
                      "toDate",
                      new Date(date).toLocaleDateString()
                    )
                  }
                />

                <Button onClick={GetDataFromServer}>{t("filter")}</Button>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className="p-20 br-8 shadow-xs">
              <Box className="d-flex j-between a-center">
                <Box>
                  <Typography variant="h5" className="gray-color fw-5">
                    {t("grossProfit")}
                  </Typography>
                  <Typography variant="subtitle2" className="gray-color">
                    ({t("totalSellPrice")} - {t("totalPurchasePrice")})
                  </Typography>
                </Box>
                <IconButton className="btn-primary">
                  <AttachMoneyRounded />
                </IconButton>
              </Box>
              <Box className="mt-20 mb-5">
                <Typography variant="h4" className="deepDark-color fw-5">
                  {org.curSymbol} {accounts.grossProfit.toFixed(org.decimal)}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className="p-20 br-8 shadow-xs">
              <Box className="d-flex j-between a-center">
                <Box>
                  <Typography variant="h5" className="gray-color fw-5">
                    {t("netProfit")}
                  </Typography>
                  <Typography variant="subtitle2" className="gray-color">
                    ({t("grossSell")} - ({t("totalPurchese")} +{" "}
                    {t("totalExpences")}))
                  </Typography>
                </Box>
                <IconButton className="btn-primary">
                  <AttachMoneyRounded />
                </IconButton>
              </Box>
              <Box className="mt-20 mb-5">
                <Typography variant="h4" className="deepDark-color fw-5">
                  {org.curSymbol} {accounts.netProfit.toFixed(org.decimal)}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          {/* Inventory */}
          <Grid item xs={12} md={6}>
            <Paper className="br-8 shadow-xs pb-10 pt-10">
              <Box className="tx-center mb-20">
                <Typography
                  variant="subtitle1"
                  className="fs-20 fw-5 gray-color"
                >
                  {t("inventory")}
                </Typography>
                <div className="bs-bottom mx-w-60 m-auto" />
              </Box>
              <Divider />
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("openingInventoryValue")}:</TableCell>
                    <TableCell>
                      {org.curSymbol}{" "}
                      {accounts.openingInvVal.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("newInventoryAdded")}:</TableCell>
                    <TableCell>
                      {org.curSymbol} {accounts.newInvVal.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("inventorySold")}:</TableCell>
                    <TableCell>
                      {org.curSymbol} {accounts.soldInvVal.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: "var(--primaryTXS-color)" }}>
                    <TableCell>{t("currentInventoryValue")}:</TableCell>
                    <TableCell>
                      {org.curSymbol}{" "}
                      {accounts.currentInvVal.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Paper>
          </Grid>

          {/* Store details */}
          <Grid item xs={12} md={6}>
            <Paper className="br-8 shadow-xs pb-10 pt-10">
              <Box className="tx-center mb-20">
                <Typography
                  variant="subtitle1"
                  className="fs-20 fw-5 gray-color"
                >
                  {t("storeDetails")}
                </Typography>
                <div className="bs-bottom mx-w-60 m-auto" />
              </Box>
              <Divider />
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("totalItemSold")}:</TableCell>
                    <TableCell>
                      {accounts.totalItem.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("totalVoidSale")}:</TableCell>
                    <TableCell>
                      {org.curSymbol}{" "}
                      {accounts.totalVoidAmount.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("totalRefund")}:</TableCell>
                    <TableCell>
                      {org.curSymbol}{" "}
                      {accounts.totalRefund.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("totalItemCancelledFromCart")}:</TableCell>
                    <TableCell>
                      {accounts.totalCancelCart.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Paper>
          </Grid>

          {/* Items */}
          <Grid item xs={12} md={6}>
            <Paper className="br-8 shadow-xs pb-10 pt-10">
              <Box className="tx-center mb-20">
                <Typography
                  variant="subtitle1"
                  className="fs-20 fw-5 gray-color"
                >
                  {t("items")}
                </Typography>
                <div className="bs-bottom mx-w-60 m-auto" />
              </Box>
              <Divider />
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("totalSale")}:</TableCell>
                    <TableCell>
                      {org.curSymbol} {accounts.totalSells.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("totalPurchase")}:</TableCell>
                    <TableCell>
                      {org.curSymbol} {accounts.totalPurch.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t("totalItemSold")}:</TableCell>
                    <TableCell>
                      {accounts.totalItem.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Paper>
          </Grid>

          {/* Expenses */}
          <Grid item xs={12} md={6}>
            <Paper className="br-8 shadow-xs pb-10 pt-10">
              <Box className="tx-center mb-20">
                <Typography
                  variant="subtitle1"
                  className="fs-20 fw-5 gray-color"
                >
                  {t("expenses")}
                </Typography>
                <div className="bs-bottom mx-w-60 m-auto" />
              </Box>
              <Divider />
              <Table size="small" sx={{ maxHeight: "200px", overflow: "auto" }}>
                <TableHead>
                  {expenses.map((exp, i) => (
                    <TableRow key={i}>
                      <TableCell>{exp.catName}:</TableCell>
                      <TableCell>$ {exp.amount.toFixed(org.decimal)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow sx={{ bgcolor: "var(--primaryTXS-color)" }}>
                    <TableCell>{t("totalExpenses")} :</TableCell>
                    <TableCell>
                      {org.curSymbol} {accounts.totalExps.toFixed(org.decimal)}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default StoreReports;
