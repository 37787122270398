import React, { useEffect } from "react";
import { InputCheck } from "../../../controls";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";

const DashbaordAccess = ({ setFeaturesAccess, featuresAccess }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!featuresAccess.id) {
      featuresAccess.isHaveDashboardAccess
        ? setFeaturesAccess((j) => ({
            ...j,
            dashboard: {
              canSeePeriodicalWidget: true,
              canSeeMonthlyAccountWidget: true,
              canSeeMonthlySalesReportChart: true,
              canSeeMulticurrencyTable: true,
              canSeeInventoryValueWidget: true,
              canSeePurchaseAndSellsChart: true,
              canSeeTopProfitMakingItemChart: true,
              canSeeSalesByEmployeeChart: true,
              canSeeProfitBreakdownChart: true,
              canSeeStoreExpenseChart: true,
              canSeeTopSellingProductsTable: true,
            },
          }))
        : setFeaturesAccess((j) => ({
            ...j,
            dashboard: {
              canSeePeriodicalWidget: false,
              canSeeMonthlyAccountWidget: false,
              canSeeMonthlySalesReportChart: false,
              canSeeMulticurrencyTable: false,
              canSeeInventoryValueWidget: false,
              canSeePurchaseAndSellsChart: false,
              canSeeTopProfitMakingItemChart: false,
              canSeeSalesByEmployeeChart: false,
              canSeeProfitBreakdownChart: false,
              canSeeStoreExpenseChart: false,
              canSeeTopSellingProductsTable: false,
            },
          }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresAccess.isHaveDashboardAccess]);

  const handleInpChg = (e) => {
    const { name, checked } = e.target;
    setFeaturesAccess((j) => ({
      ...j,
      dashboard: { ...j.dashboard, [name]: checked },
    }));
  };

  return (
    <Collapse in={featuresAccess.isHaveDashboardAccess || false}>
      <div className="ml-25 d-grid g-5 mt-5">
        <InputCheck
          label={t("canSeePeriodicalWidget")}
          checked={
            (featuresAccess.dashboard &&
              featuresAccess.dashboard.canSeePeriodicalWidget) ||
            false
          }
          name={"canSeePeriodicalWidget"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeMonthlyAccountWidget")}
          checked={
            (featuresAccess.dashboard &&
              featuresAccess.dashboard.canSeeMonthlyAccountWidget) ||
            false
          }
          name={"canSeeMonthlyAccountWidget"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeMonthlySalesReportChart")}
          checked={
            (featuresAccess.dashboard &&
              featuresAccess.dashboard.canSeeMonthlySalesReportChart) ||
            false
          }
          name={"canSeeMonthlySalesReportChart"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeMulticurrencyTable")}
          checked={
            (featuresAccess.dashboard &&
              featuresAccess.dashboard.canSeeMulticurrencyTable) ||
            false
          }
          name={"canSeeMulticurrencyTable"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeInventoryValueWidget")}
          checked={
            (featuresAccess.dashboard &&
              featuresAccess.dashboard.canSeeInventoryValueWidget) ||
            false
          }
          name={"canSeeInventoryValueWidget"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeePurchaseAndSellsChart")}
          checked={
            (featuresAccess.dashboard &&
              featuresAccess.dashboard.canSeePurchaseAndSellsChart) ||
            false
          }
          name={"canSeePurchaseAndSellsChart"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeTopProfitMakingItemChart")}
          checked={
            (featuresAccess.dashboard &&
              featuresAccess.dashboard.canSeeTopProfitMakingItemChart) ||
            false
          }
          name={"canSeeTopProfitMakingItemChart"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeSalesByEmployeeChart")}
          checked={
            (featuresAccess.dashboard &&
              featuresAccess.dashboard.canSeeSalesByEmployeeChart) ||
            false
          }
          name={"canSeeSalesByEmployeeChart"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeProfitBreakdownChart")}
          checked={
            (featuresAccess.dashboard &&
              featuresAccess.dashboard.canSeeProfitBreakdownChart) ||
            false
          }
          name={"canSeeProfitBreakdownChart"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeStoreExpenseChart")}
          checked={
            (featuresAccess.dashboard &&
              featuresAccess.dashboard.canSeeStoreExpenseChart) ||
            false
          }
          name={"canSeeStoreExpenseChart"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("canSeeTopSellingProductsTable")}
          checked={
            (featuresAccess.dashboard &&
              featuresAccess.dashboard.canSeeTopSellingProductsTable) ||
            false
          }
          name={"canSeeTopSellingProductsTable"}
          onClick={handleInpChg}
        />
      </div>
    </Collapse>
  );
};

export default DashbaordAccess;
