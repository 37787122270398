import { Divider, Grid, Paper } from "@mui/material";
import React, { useContext } from "react";
import { Button, Input } from "../../controls";
import { Alert, Form } from "../../layout";
import { Model, UseForm } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { controller, endpoints } from "../../api/actions/api";
import * as actions from "../../api/actions/pos";
import { RmvEmptyProp, ToFormData } from "../../hooks/Method";
import { useEffect } from "react";
import { useState } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const AddEditSplr = (props) => {
  const { _post, _put, _getById, _authUser } = props;
  const { SuppliersMdl } = Model();
  const { selStore, setPageTitle, pageTitle } = useContext(AppContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const { values, setValues, errors, setErrors, handleInpChg } =
    UseForm(SuppliersMdl);

  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const validateForm = () => {
    let temp = {};
    temp.firstName = values.firstName !== "" ? "" : t("firstNameIsRequired");
    temp.lastName = values.lastName !== "" ? "" : t("lastNameIsRequired");
    temp.phone = values.phone !== "" ? "" : t("phoneIsRequired");
    temp.company = values.company !== "" ? "" : t("companyNameIsRequired");
    temp.email =
      values.email !== ""
        ? /^$|.+@.+..+/.test(values.email)
          ? ""
          : t("emailIsNotValid")
        : t("emailIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        res.status === 200 || res.status === 201
          ? setAlert({
              isOpen: true,
              type: "success",
              title: t("success"),
              subTitle: `${t("supplier")} ${id ? t("updated") : t("added")} ${t(
                "successfully"
              )}`,
              onClick: () => navigate("/suppliers"),
            })
          : setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
      };
      values.userId = _authUser.id;
      values.purchases = "";
      // Call Api
      if (values.id === 0) {
        values.storeId = selStore;
        _post(
          endpoints.Supplier,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      } else {
        _put(
          endpoints.Supplier,
          ToFormData(RmvEmptyProp(values)),
          null,
          onComplete
        );
      }
    }
  };

  useEffect(() => {
    setPageTitle &&
      setPageTitle((!id ? t("add") : t("update")) + " " + t("supplier"));
    id &&
      _getById(endpoints.Supplier + controller.GetById, id, null, (res) =>
        setValues((val) => ({ ...val, ...res.result }))
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Paper className="mt-30 shadow-xs br-8">
        <Form noValidate onSubmit={handleSubmit}>
          <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
            <div>
              <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
                {pageTitle}
              </h1>
            </div>

            <div>
              <Button
                loading={loading}
                type="submit"
                variant="text"
                size="large"
                sx={{ width: { md: "150px", xs: "100%" } }}
              >
                {!id ? t("submit") : t("saveChanges")}
              </Button>
            </div>
          </div>
          <Divider />
          <Grid container spacing={3} className="p-20 pt-0">
            <Grid item xs={12}>
              <Input
                label={t("companyName")}
                name="company"
                value={values.company}
                error={errors.company}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label={t("firstName")}
                name="firstName"
                value={values.firstName}
                error={errors.firstName}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label={t("lastName")}
                name="lastName"
                value={values.lastName}
                error={errors.lastName}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label={t("email")}
                name="email"
                value={values.email}
                error={errors.email}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 350 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label={t("phone")}
                name="phone"
                value={values.phone}
                error={errors.phone}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label={t("productsSale")}
                name="productSale"
                value={values.productSale}
                error={errors.productSale}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 800 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("city")}
                name="city"
                value={values.city}
                error={errors.city}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 400 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("state")}
                name="state"
                value={values.state}
                error={errors.state}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 400 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("ZIP")}
                name="zip"
                value={values.zip}
                error={errors.zip}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 300 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label={t("address")}
                name="address"
                value={values.address}
                error={errors.address}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 800 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label={t("note")}
                name="note"
                multiline
                rows={2}
                value={values.note}
                error={errors.note}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 1000 }}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _data: state.pos.data,
  _type: state.pos.type,
  _authUser: state.pos.authUser,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditSplr);
