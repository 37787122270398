import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/pos";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { controller, endpoints } from "../../../api/actions/api";
import { IconButton, Tooltip } from "@mui/material";
import { Alert, Tabs } from "../../../layout";
import SplrDetails from "./SplrDetails";
import PurchReceipt from "./PurchReceipt";
import { DeleteForeverRounded, EditRounded } from "@mui/icons-material";
import { AppContext } from "../../../App";

const ViewSplr = ({ _getById, _delete }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const navigate = useNavigate();
  const { authUser, setPageTitle } = useContext(AppContext);
  const [access, setAccess] = useState({});

  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setPageTitle(t("supplierDetails"));
    if (authUser && authUser.featuresAccess && authUser.featuresAccess.supplier)
      setAccess({ ...authUser.featuresAccess.supplier });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const handleAddEdit = (id) => {
    navigate(`/suppliers/supplier-form/${id || ""}`);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("splrDltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res && res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
            navigate("/suppliers");
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: t("error"),
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.Supplier, id, null, onComplete);
      },
    });
  };

  useEffect(() => {
    id &&
      _getById(
        endpoints.Supplier + controller.GetById,
        id,
        null,
        (res) => res.status === 200 && setValues({ ...res.result })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <div>
        <div className="mb-15 mt-20 d-flex flex-wrap a-center g-10 j-between">
          <div>
            <span className="fs-20 tx-upp gray-color border-left">
              {t("supplierDetails")}
            </span>
          </div>
          <div className="bs-secondary br-25 pl-5 pr-5 w-fit d-flex g-10">
            {access.isHaveEditAccess && (
              <Tooltip title={t("edit")}>
                <IconButton
                  onClick={() => handleAddEdit(values.id)}
                  size="small"
                  color="primary"
                >
                  <EditRounded className="fs-24 m-2" />
                </IconButton>
              </Tooltip>
            )}
            {access.isHaveDeleteAccess && (
              <Tooltip title={t("delete")}>
                <IconButton
                  onClick={() => handleDlt(values.id)}
                  size="small"
                  color="error"
                >
                  <DeleteForeverRounded className="fs-24 m-2" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        <div>
          {values && values.id && (
            <Tabs header={[{ label: t("details") }, { label: t("receipt") }]}>
              <SplrDetails {...{ values }} />
              <PurchReceipt {...{ values, setValues }} />
            </Tabs>
          )}
        </div>
      </div>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(ViewSplr);
