import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { DropLi } from "../../../hooks";
import { IsEmptyObj } from "../../../hooks/Method";
import { CloseRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../App";

const ViewReturnItems = (props) => {
  const { setShowForm, viewData } = props;
  const [total, setTotal] = useState(0);
  const { org } = useContext(AppContext);
  const { t } = useTranslation();
  const { ReturnReason } = DropLi();

  useEffect(() => {
    if (!IsEmptyObj(viewData)) {
      var total = viewData.purchReturnItems
        .map((j) => j.qty * j.product.purchPrice)
        .reduce((x, y) => x + y, 0)
        .toFixed(org.decimal);
      setTotal(total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewData]);

  const getReasonIdByName = (id) => {
    var reason = ReturnReason.find((j) => j.id === id);
    return reason ? reason.name : "";
  };

  return (
    <div>
      <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
        <div>
          <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
            {t("returnItemDetails")}
          </h1>
        </div>

        <div>
          <IconButton onClick={() => setShowForm(false)}>
            <CloseRounded />
          </IconButton>
        </div>
      </div>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <TableContainer className="mt-20">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("itemName")}</TableCell>
                  <TableCell>{t("barcode")}</TableCell>
                  <TableCell>{t("returnReason")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("price")}</TableCell>
                  <TableCell>{t("total")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewData.purchReturnItems.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>{item.product && item.product.name}</TableCell>
                    <TableCell>
                      {item.product && item.product.barcode}
                    </TableCell>
                    <TableCell>
                      {getReasonIdByName(item.returnReason)}
                    </TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>
                      {(org && org.curSymbol) +
                        " " +
                        item.product.purchPrice.toFixed(org.decimal)}
                    </TableCell>
                    <TableCell>
                      {(org && org.curSymbol) +
                        " " +
                        (item.qty * item.product.purchPrice).toFixed(
                          org.decimal
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="w-100pr"
          sx={{
            borderRight: { sm: "1px solid var(--grayLight-color)" },
          }}
        >
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("referenceNo")}:</TableCell>
                  <TableCell align="right">{viewData.purchase.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("supplier")} :</TableCell>
                  <TableCell align="right">
                    {viewData.purchase.supplier.company}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("returnUser")} :</TableCell>
                  <TableCell align="right">
                    {viewData.user.firstName + " " + viewData.user.lastName}
                  </TableCell>
                </TableRow>
                {viewData.recept && (
                  <TableRow>
                    <TableCell>{t("purchaseRecept")} :</TableCell>
                    <TableCell align="right">
                      {viewData.purchase.recept && (
                        <a
                          href={viewData.purchase.recept}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t("viewRecept")}
                        </a>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} className="w-100pr">
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("purchaseDate")}: </TableCell>
                  <TableCell align="right">{viewData.returnDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("totalItem")}:</TableCell>
                  <TableCell align="right">
                    {viewData.purchReturnItems
                      .map((j) => j.qty)
                      .reduce((x, y) => x + y, 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("total")}: </TableCell>
                  <TableCell align="right">
                    {(org && org.curSymbol) + " " + total}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewReturnItems;
