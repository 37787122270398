import React, { useEffect } from "react";
import { InputCheck } from "../../../controls";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";

const PurchaseReturnAccess = ({ setFeaturesAccess, featuresAccess }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!featuresAccess.id) {
      featuresAccess.isHavePurchaseReturnAccess
        ? setFeaturesAccess((x) => ({
            ...x,
            purchaseReturn: {
              isHaveReturnAccess: true,
              isHaveViewAccess: true,
              isHaveExportAccess: true,
              isHaveDeleteAccess: true,
              isHaveEditAccess: true,
              isHaveViewReceiptAccess: true,
            },
          }))
        : setFeaturesAccess((j) => ({
            ...j,
            purchaseReturn: {
              isHaveReturnAccess: false,
              isHaveViewAccess: false,
              isHaveExportAccess: false,
              isHaveDeleteAccess: false,
              isHaveEditAccess: false,
              isHaveViewReceiptAccess: false,
            },
          }));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresAccess.isHavePurchaseReturnAccess]);

  const handleInpChg = (e) => {
    const { name, checked } = e.target;
    setFeaturesAccess((j) => ({
      ...j,
      purchaseReturn: { ...j.purchaseReturn, [name]: checked },
    }));
  };
  return (
    <Collapse in={featuresAccess.isHavePurchaseReturnAccess || false}>
      <div className="ml-25 d-grid g-5 mt-5">
        <InputCheck
          label={t("isHaveViewAccess")}
          checked={
            (featuresAccess.purchaseReturn &&
              featuresAccess.purchaseReturn.isHaveViewAccess) ||
            false
          }
          name={"isHaveViewAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveReturnAccess")}
          checked={
            (featuresAccess.purchaseReturn &&
              featuresAccess.purchaseReturn.isHaveReturnAccess) ||
            false
          }
          name={"isHaveReturnAccess"}
          onClick={handleInpChg}
        />

        <InputCheck
          label={t("isHaveEditAccess")}
          checked={
            (featuresAccess.purchaseReturn &&
              featuresAccess.purchaseReturn.isHaveEditAccess) ||
            false
          }
          name={"isHaveEditAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveDeleteAccess")}
          checked={
            (featuresAccess.purchaseReturn &&
              featuresAccess.purchaseReturn.isHaveDeleteAccess) ||
            false
          }
          name={"isHaveDeleteAccess"}
          onClick={handleInpChg}
        />

        <InputCheck
          label={t("isHaveExportAccess")}
          checked={
            (featuresAccess.purchaseReturn &&
              featuresAccess.purchaseReturn.isHaveExportAccess) ||
            false
          }
          name={"isHaveExportAccess"}
          onClick={handleInpChg}
        />
        <InputCheck
          label={t("isHaveViewReceiptAccess")}
          checked={
            (featuresAccess.purchaseReturn &&
              featuresAccess.purchaseReturn.isHaveViewReceiptAccess) ||
            false
          }
          name={"isHaveViewReceiptAccess"}
          onClick={handleInpChg}
        />
      </div>
    </Collapse>
  );
};

export default PurchaseReturnAccess;
