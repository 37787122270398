import { ThemeProvider } from "@emotion/react";
import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Auth, DashRoute, CLoader } from "./component";
import { connect } from "react-redux";
import * as actions from "./api/actions/pos";
import { controller, endpoints } from "./api/actions/api";
import { Loader } from "./controls";
import { IsEmptyObj } from "./hooks/Method";
import theme from "./utils/theme";
import "./scss/App.scss";
import "./scss/JHProg.scss";
import { useTranslation } from "react-i18next";

export const AppContext = createContext({});

function App(props) {
  const { _get, _authUser, _getById } = props;
  const { i18n } = useTranslation();
  const [authUser, setAuthUser] = useState({});
  const [pageTitle, setPageTitle] = useState();
  const [loader, setLoader] = useState(false);
  const [cLoader, setCLoader] = useState(false);
  const [selStore, setSelStore] = useState(0);
  const [stores, setStores] = useState([]);
  const [org, setOrg] = useState({});
  const [defaultStore, setDefaultStore] = useState();

  useEffect(() => {
    _get(
      endpoints.Auth + controller.GetAuthUser,
      actions.ActionType.Auth,
      (res) => res && res.status === 200 && setAuthUser({ ...res.result })
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stores.length > 0)
      setDefaultStore(stores.find((j) => j.id === selStore));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore, stores]);

  useEffect(() => {
    if (defaultStore) {
      document.title = `${pageTitle} - ${
        defaultStore ? defaultStore.storeName : ""
      }`;
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitle, defaultStore]);

  useEffect(() => {
    if (_authUser.id) {
      _getById(
        endpoints.Currency + controller.GetById,
        _authUser.org.currId,
        null,
        (res) => {
          setOrg({
            ..._authUser.org,
            curSymbol: res.result ? res.result.symbol : "",
            curName: res.result ? res.result.currency : "",
          });
        }
      );

      // Set Language
      i18n.changeLanguage(_authUser.language || "en");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authUser]);

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider
        value={{
          authUser,
          setAuthUser,
          loader,
          setLoader,
          cLoader,
          setCLoader,
          stores,
          setStores,
          selStore,
          setSelStore,
          org,
          setOrg,
          setPageTitle,
          pageTitle,
        }}
      >
        <Loader show={loader} />
        <CLoader show={cLoader} />
        <Router>
          <Routes>
            <Route
              path="/*"
              element={!IsEmptyObj(_authUser) ? <DashRoute /> : <Auth />}
            />
          </Routes>
        </Router>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActiontoProps = {
  _get: actions.get,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActiontoProps)(App);
