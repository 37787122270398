import { Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { action, controller, Server } from "../../api/Server";
import { AppContext } from "../../App";
import SearchCats from "./SearchCats";

const Categories = () => {
  const { selStore } = useContext(AppContext);
  const [fltCats, setFltCats] = useState([]);
  const [cats, setCats] = useState([]);
  const navigate = useNavigate();
  const viewProducts = (id) => {
    navigate(`/pos/products/${id}`);
  };

  const getCategories = () => {
    if (selStore) {
      Server(controller.Inventory + action.GetCatsByStore).post(
        { storeId: selStore },
        (res) => {
          if (res && res.status === 200) {
            setCats([...res.result]);
            setFltCats([...res.result]);
          }
        }
      );
    }
  };

  useEffect(() => {
    getCategories();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <Grid container spacing={3} className="pr-10">
      <Grid item xs={12}>
        <SearchCats {...{ cats, setFltCats }} />
      </Grid>
      {fltCats.map((cat, i) => (
        <Grid item xs={12} md={3} key={i}>
          <Paper
            onClick={() => viewProducts(cat.id)}
            sx={{ minHeight: "90px" }}
            className="br-8 shadow-xs p-20 d-flex j-center a-center c-pointer"
          >
            <Typography varing="h6" className="fs-22 fw-5 gray-color">
              {cat.name}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Categories;
