import React, { useEffect, useState } from "react";
import { Table } from "../../layout";
import { useContext } from "react";
import { AppContext } from "../../App";
import { action, controller, Server } from "../../api/Server";
import { useTranslation } from "react-i18next";
import { ChangeArrayKey } from "../../hooks/Method";
import { Box } from "@mui/material";
import { CSVLink } from "react-csv";

const ProductReport = () => {
  const { setLoader, selStore, org, setPageTitle } = useContext(AppContext);
  const { t } = useTranslation();
  const [sells, setSells] = useState([]);
  const [csvHeader, setCsvHeader] = useState([]);

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      Server(controller.Report + action.ProductsReport).getById(
        selStore,
        (res) => {
          setLoader(false);
          res && res.status === 200 && setSells([...res.result]);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    setPageTitle(t("productReport"));
    setCsvHeader([
      { key: "productName", label: t("productName") },
      { key: "totalSold", label: t("totalSold") },
      { key: "purchasePrice", label: t("purchasePrice") },
      { key: "sellPrice", label: t("sellPrice") },
      { key: "profit", label: t("profit") },
    ]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HeaderComp = () => (
    <div className="d-flex flex-wrap g-5">
      <Box
        className="d-flex"
        sx={{
          width: { xs: "100%", md: "auto" },
        }}
      >
        <CSVLink
          data={sells}
          headers={csvHeader}
          filename={`${t("productReport")} (${new Date().toDateString()}).csv`}
          target="_blank"
          className="d-flex a-center j-center p-15 fs-16 fw-5 tx-upp primary-color bg-primarySM br-15"
        >
          {t("exportCSV")}
        </CSVLink>
      </Box>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={HeaderComp}
        tblHead={[...ChangeArrayKey(csvHeader, "key", "id")]}
        tblData={sells.map((prod) => {
          return {
            ...prod,
            totalSold:
              org.curSymbol + " " + prod.totalSold.toFixed(org.decimal),
            purchasePrice:
              org.curSymbol + " " + prod.purchasePrice.toFixed(org.decimal),
            sellPrice:
              org.curSymbol + " " + prod.sellPrice.toFixed(org.decimal),
            profit: org.curSymbol + " " + prod.profit.toFixed(org.decimal),
          };
        })}
      />
    </>
  );
};

export default ProductReport;
