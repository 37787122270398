import { Grid } from "@mui/material";
import { Tabs } from "../../../layout";
import React, { useContext, useEffect, useState } from "react";
import CustomerInfo from "./CustomerInfo";
import Details from "./Details";
import Accounts from "./Accounts";
import { useParams } from "react-router-dom";
import { DropLi } from "../../../hooks";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/pos";
import { controller, endpoints } from "../../../api/actions/api";
import { useTranslation } from "react-i18next";
import Receipt from "./Receipt";
import { AppContext } from "../../../App";

const ViewCstmr = ({ _getById }) => {
  const { id } = useParams();
  const [cstmr, setCstmr] = useState();
  const { t } = useTranslation();
  const { authUser, setPageTitle } = useContext(AppContext);
  const { Gender, AccBalTypes } = DropLi();
  const [gender, setGender] = useState("");
  const [access, setAccess] = useState({});
  const [accBalType, setAccBalType] = useState("");

  useEffect(() => {
    if (authUser && authUser.featuresAccess && authUser.featuresAccess.customer)
      setAccess({ ...authUser.featuresAccess.customer });
    return () => {};
  }, [authUser]);

  useEffect(() => {
    id &&
      _getById(
        endpoints.Customer + controller.GetById,
        id,
        null,
        (res) => res.status === 200 && setCstmr({ ...res.result })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (cstmr) {
      cstmr && setPageTitle(cstmr.firstName + " " + cstmr.lastName);
      // get name of the gender
      var _gender = Gender.find((j) => j.id === cstmr.gender);
      setGender(_gender ? _gender.label : "");

      // get name of the account balance type
      var _balType = AccBalTypes.find((j) => j.id === cstmr.accBalType);
      setAccBalType(_balType ? _balType.label : "");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cstmr]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8.5}>
          <Tabs
            header={[
              { label: t("details") },
              ...(access.isHaveReceiptViewAccess
                ? [{ label: t("receiptOrDue") }]
                : []),
              ...(access.isHaveAccountsViewAccess
                ? [{ label: t("accounts") }]
                : []),
            ]}
          >
            <Details {...{ cstmr, gender, accBalType }} />
            {access.isHaveReceiptViewAccess && (
              <Receipt {...{ cstmr, accBalType }} />
            )}
            {access.isHaveAccountsViewAccess && (
              <Accounts {...{ cstmr, accBalType }} />
            )}
          </Tabs>
        </Grid>
        <Grid item xs={12} md={3.5}>
          <CustomerInfo {...{ cstmr, gender, accBalType, access }} />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(ViewCstmr);
