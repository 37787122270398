import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Input } from "../../../controls";
import * as actions from "../../../api/actions/pos";
import { Model, UseForm } from "../../../hooks";
import { controller, endpoints } from "../../../api/actions/api";
import { NestedFormData, PrintDiv, RmvEmptyProp } from "../../../hooks/Method";
import { AppContext } from "../../../App";
import { useTranslation } from "react-i18next";
import CustomerSect from "./CustomerSect";
import AdditionalDiscount from "./AdditionalDiscount";
import AmountToPay from "./PaymentReceived/AmountToPay";
import PaymentReceived from "./PaymentReceived/PaymentReceived";

const Checkout = (props) => {
  const {
    subTotal,
    cartItems,
    totalPay,
    setTotalPay,
    tax,
    setReceptData,
    handleClearCart,
    _post,
    _authUser,
    _getById,
    setAlert,
    setTax,
    setTaxPct,
  } = props;
  const { selStore, stores, org, setPageTitle } = useContext(AppContext);
  const { SellMdl } = Model();
  const { t } = useTranslation();
  const {
    values,
    errors,
    setErrors,
    handleInpChg,
    handleInpCheck,
    handleInpDate,
    setValues,
  } = UseForm(SellMdl);
  const [needToPayAmt, setNeedToPayAmt] = useState(totalPay || 0);
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState({});
  const [payMethods, setPayMethods] = useState([]);
  const [access, setAccess] = useState({});

  useEffect(() => {
    if (_authUser && _authUser.featuresAccess && _authUser.featuresAccess.pos)
      setAccess({ ..._authUser.featuresAccess.pos });
    return () => {};
  }, [_authUser]);

  useEffect(() => {
    org.id &&
      _getById(
        endpoints.PayMethod + controller.GetAllByOrg,
        org.id,
        null,
        (res) =>
          setPayMethods([...res.result.map((j) => ({ ...j, label: j.title }))])
      );

    setPageTitle(t("checkout"));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  // Get Selected or Default store details
  useEffect(() => {
    setStore(stores.find((j) => j.id === selStore));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  // Define default data.
  useEffect(() => {
    setValues((j) => ({
      ...j,
      storeId: selStore,
      sellItems: cartItems,
      taxPct: store ? store.tax : 0,
      tax: tax,
      totalPay: totalPay,
      received: "",
      discount: "",
      subtotal: subTotal.toFixed(org.decimal),

      userId: _authUser.id,
      paymentType: 1,
      payMethodId: payMethods[0] ? payMethods[0].id : "",
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPay, store]);

  // =======** Calculate Total Amount to Pay **=======
  useEffect(() => {
    var _disAmt = parseFloat(values.disAmt || 0);
    var _dueAmt = parseFloat(values.dueAmt || 0);
    var _creditAmt = parseFloat(values.creditAmt || 0);
    var _received = parseFloat(values.received || 0);

    var _leftAmt = (
      totalPay -
      (_disAmt + _dueAmt + _creditAmt + _received)
    ).toFixed(org.decimal);
    setNeedToPayAmt(parseFloat(_leftAmt));

    setValues((j) => ({
      ...j,
      totalPay: (totalPay - (_disAmt + _dueAmt)).toFixed(org.decimal),
      change: Math.abs(_leftAmt < 0 ? _leftAmt : 0),
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalPay,
    values.disAmt,
    values.dueAmt,
    values.creditAmt,
    values.received,
  ]);

  useEffect(() => {
    var _totalReceived = values.payments.reduce(
      (a, b) => a + parseFloat(b.amount),
      0
    );
    setValues((j) => ({
      ...j,
      received: parseFloat(_totalReceived.toFixed(org.decimal)),
    }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.payments, values.cstmrId]);

  // Calculate Discount Amount
  useEffect(() => {
    if (values.discountType === 1) {
      setValues((j) => ({
        ...j,
        disAmt: (
          (parseFloat(totalPay) * parseFloat(values.discount || 0)) /
          100
        ).toFixed(org.decimal),
      }));
    } else if (values.discountType === 2)
      setValues((j) => ({ ...j, disAmt: j.discount }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.discountType, values.discount]);

  const validateForm = () => {
    let temp = {};
    temp.dueDate =
      values.sellType === 2 && !values.dueDate ? t("dueDateIsRequired") : "";
    temp.dueAmt =
      values.sellType === 2 && !parseFloat(values.dueAmt || 0)
        ? t("dueAmtIsReq")
        : "";
    temp.received = needToPayAmt > 0 && 1 < needToPayAmt ? t("payMoreAmt") : "";

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    // **** Submit Action ****
    if (values.sellItems.length > 0 && validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(true);
        if (res.status === 200) {
          setReceptData(res.result);
          setTimeout(() => {
            PrintDiv("printSellRecept");
            handleClearCart();
          }, 1500);
        } else {
          setLoading(false);
          setAlert({
            isOpen: true,
            type: "warning",
            title: t("warning"),
            subTitle: res.msg,
          });
        }
      };
      // Differential Amount
      if (needToPayAmt < 1 && needToPayAmt > 0) values.diffAmt = needToPayAmt;
      values.sellItems = values.sellItems.map((j) =>
        j.itemType === 2 ? { ...j, productId: "" } : j
      );

      _post(
        endpoints.Sell,
        NestedFormData(RmvEmptyProp(values)),
        null,
        onComplete
      );
    } else if (values.sellItems.length <= 0) {
      setAlert({
        isOpen: true,
        type: "warning",
        title: t("warning"),
        subTitle: t("noItemInCart"),
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* Customer Loyalty Section */}
        <Grid item xs={12}>
          <CustomerSect
            {...{
              values,
              setValues,
              errors,
              handleInpChg,
              handleInpDate,
              handleSubmit,
              loading,
              setTax,
              store,
              totalPay,
              needToPayAmt,
              setTotalPay,
              setTaxPct,
              access,
            }}
          />
        </Grid>

        {/* Amount to Pay */}
        <Grid item xs={12}>
          <AmountToPay {...{ needToPayAmt, values, handleInpCheck, org }} />
        </Grid>

        {/* Additional Discount */}
        {access.isHaveGivingDiscountAccess && (
          <AdditionalDiscount
            {...{ values, setValues, handleInpChg, totalPay }}
          />
        )}

        {/* Payment Received  */}
        <Grid item xs={12}>
          <PaymentReceived
            {...{ needToPayAmt, values, setValues, handleInpCheck }}
          />
        </Grid>

        {/* Note */}
        <Grid item xs={12} className="mt-10">
          <Input
            fullWidth
            placeholder={t("keepNoteIfYouWant")}
            name="note"
            value={values.note}
            onChange={handleInpChg}
            className="bg-white rmv-arrow br-8"
            variant="outlined"
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
  _type: state.pos.type,
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(Checkout);
