import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const PrintRegReport = ({
  totalSells,
  average,
  refund,
  voidAmount,
  cashSale,
  craditSale,
  taxItem,
  nonTaxItem,
  taxReceived,
  store,
  _authUser,
  expense,
  soldByCats,
  sells,
  openData,
  cancelCarts,
  otherPayment,
}) => {
  const { t } = useTranslation();
  const { org } = useContext(AppContext);

  const title_sm_cls = {
    borderLeft: "0.4rem double #007bff",
    paddingLeft: "7px",
    fontSize: "16px !important",
    color: "#7a7a7a",
    textTransform: "uppercase",
    fontWeight: "400",
  };

  const label_cls = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #7a7a7a1f",
    padding: "7px 0px 7px 0px",
  };

  const table_cls = {
    fontFamily: "arial, sans-serif",
    borderCollapse: "collapse",
    width: "100%",
  };

  const td_th_cls = {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "5px",
    fontSize: "12px",
  };

  const value_cls = {
    color: "#7a7a7a",
    fontWeight: "500",
    width: "50%",
  };

  return (
    <>
      {sells ? (
        <div style={{ fontFamily: "arial, sans-serif", fontSize: "14px" }}>
          {/* ===== Section 1 ===== */}
          <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            {/* ===== Cashier Details ===== */}
            <div style={{ width: "48%" }}>
              <div
                style={{
                  borderBottom: "1px solid #7a7a7a1f",
                  paddingBottom: "8px",
                }}
              >
                <span style={title_sm_cls}>{t("cashierDetails")}</span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("storeName")} :</span>
                <span className={value_cls}>{store.storeName}</span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("address")} :</span>
                <span className={value_cls}>{store.address}</span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("cashierName")} :</span>
                <span className={value_cls}>
                  {_authUser.firstName + " " + _authUser.lastName}{" "}
                </span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("timeIn")} :</span>
                <span className={value_cls}>
                  {new Date(openData.timeIn).toLocaleTimeString()}
                </span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("timeOut")} :</span>
                <span className={value_cls}>
                  {new Date().toLocaleTimeString()}
                </span>
              </div>
            </div>

            {/* ===== Register Details ===== */}
            <div style={{ width: "48%" }}>
              <div
                style={{
                  borderBottom: "1px solid #7a7a7a1f",
                  paddingBottom: "8px",
                }}
              >
                <span style={title_sm_cls}>{t("registerDetails")}</span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("totalCustomers")} :</span>
                <span className={value_cls}>{sells.length}</span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>
                  {t("averagePerCustomer")} :
                </span>
                <span className={value_cls}>
                  {org && org.curSymbol} {average}
                </span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("totalSaleRefund")} :</span>
                <span className={value_cls}>
                  {org && org.curSymbol} {refund}
                </span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("totalVoidSells")} :</span>
                <span className={value_cls}>
                  {(org && org.curSymbol) + " " + voidAmount}
                </span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("totalItemCanceled")} :</span>
                <span className={value_cls}>{cancelCarts.length}</span>
              </div>
            </div>
          </div>

          {/* ===== Section 2 ===== */}
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              marginTop: "60px",
            }}
          >
            {/* ===== Sale Report ===== */}
            <div style={{ width: "48%" }}>
              <div
                style={{
                  borderBottom: "1px solid #7a7a7a1f",
                  paddingBottom: "8px",
                }}
              >
                <span style={title_sm_cls}>{t("saleReport")}</span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("totalSale")} :</span>
                <span className={value_cls}>
                  {(org && org.curSymbol) + " " + totalSells}
                </span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("totalCashSale")} :</span>
                <span className={value_cls}>
                  {(org && org.curSymbol) + " " + cashSale}
                </span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("totalCreditSale")} :</span>
                <span className={value_cls}>
                  {(org && org.curSymbol) + " " + craditSale}
                </span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>
                  {t("otherPaymentReceived")} :
                </span>
                <span className={label_cls}>
                  {(org && org.curSymbol) + " " + otherPayment}
                </span>
              </div>
            </div>

            {/* ===== Sell Details ===== */}
            <div style={{ width: "48%" }}>
              <div
                style={{
                  borderBottom: "1px solid #7a7a7a1f",
                  paddingBottom: "8px",
                }}
              >
                <span style={title_sm_cls}>{t("sellDetails")}</span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("totalGrossSale")} :</span>
                <span className={value_cls}>
                  {(org && org.curSymbol) + " " + totalSells}
                </span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("totalTaxItemSold")} :</span>
                <span className={value_cls}>{taxItem}</span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>
                  {t("totalNonTaxItemSold")} :
                </span>
                <span className={value_cls}>{nonTaxItem}</span>
              </div>
              <div style={label_cls}>
                <span style={{ width: "48%" }}>{t("totalTaxReceived")} :</span>
                <span className={value_cls}>
                  {(org && org.curSymbol) + " " + taxReceived}
                </span>
              </div>
            </div>
          </div>

          {/* ===== Sale Report ===== */}
          <div style={{ marginTop: "50px" }}>
            <div
              style={{
                borderBottom: "1px solid #7a7a7a1f",
                paddingBottom: "8px",
              }}
            >
              <span style={title_sm_cls}>{t("money")}</span>
            </div>
            <div style={label_cls}>
              <span style={{ width: "48%" }}>{t("totalCashSale")} :</span>
              <span
                style={{
                  color: "#7a7a7a",
                  fontWeight: "500",
                  width: "55%",
                }}
              >
                {(org && org.curSymbol) + " " + totalSells}
              </span>
            </div>
            {/* ------ Expenses ------ */}
            {expense.map((exp, i) => (
              <div key={i} style={label_cls}>
                <span style={{ width: "48%" }}>{exp.expenseCat.name}: :</span>
                <span className={value_cls}>
                  : {(org && org.curSymbol) + " " + exp.amount}
                </span>
              </div>
            ))}
            <div style={label_cls}>
              <span style={{ width: "48%" }}>
                {t("shiftTransactionsBalance")} :
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#7a7a7a",
                  fontWeight: "500",
                  width: "55%",
                  gap: "10px",
                }}
              >
                <span>
                  -{(org && org.curSymbol) + " " + openData.cashInDrawer}
                </span>
                {openData.multiPayAmt && (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    (
                    <span style={{ textDecorationLine: "underline" }}>
                      {t("multiPayments")}: &nbsp;{org && org.curSymbol}{" "}
                      {openData.multiPayAmt}
                    </span>
                    )
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* ===== Products Sold By Category ===== */}
          <div style={{ marginTop: "50px" }}>
            <div
              style={{
                borderBottom: "1px solid #7a7a7a1f",
                paddingBottom: "8px",
              }}
            >
              <span style={title_sm_cls}>{t("productsSoldByCategory")}</span>
            </div>
            <div>
              <table style={table_cls}>
                <thead>
                  <tr>
                    <th style={td_th_cls}>{t("categoryName")}</th>
                    <th style={td_th_cls}>{t("quantity")}</th>
                    <th style={td_th_cls}>{t("price")}</th>
                  </tr>
                </thead>
                <tbody>
                  {soldByCats.map((item, i) => (
                    <tr key={i}>
                      <td style={td_th_cls}>
                        {item[0].product.category !== null
                          ? item[0].product.category.name
                          : t("others")}
                      </td>
                      <td style={td_th_cls}>
                        {item
                          .map((j) => j.qty)
                          .reduce((x, y) => x + y, 0)
                          .toFixed(org.decimal)}
                      </td>
                      <td style={td_th_cls}>
                        {(org && org.curSymbol) +
                          " " +
                          item
                            .map((j) => j.qty * j.price)
                            .reduce((x, y) => x + y, 0)
                            .toFixed(org.decimal)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* ===== Details Of Products Sold ===== */}
          <div style={{ marginTop: "50px" }}>
            <div
              style={{
                borderBottom: "1px solid #7a7a7a1f",
                paddingBottom: "8px",
              }}
            >
              <span style={title_sm_cls}>{t("detailsOfProductsSold")}</span>
            </div>
            <div>
              <table style={table_cls}>
                <thead>
                  <tr>
                    <th style={td_th_cls}>{t("itemName")}</th>
                    <th style={td_th_cls}>{t("quantity")}</th>
                    <th style={td_th_cls}>{t("tax")}</th>
                    <th style={td_th_cls}>{t("price")}</th>
                  </tr>
                </thead>
                <tbody>
                  {sells
                    .map((j) => j.sellItems)
                    .flat()
                    .map((item, i) => (
                      <tr key={i}>
                        <td style={td_th_cls}>{item.product.name}</td>
                        <td style={td_th_cls}>
                          {item.qty.toFixed(org.decimal)}
                        </td>
                        <td style={td_th_cls}>
                          {" "}
                          {item.itemType === 1 && item.product.tax === 1
                            ? `${
                                (org && org.curSymbol) +
                                " " +
                                (
                                  (parseInt(store.tax) / 100) *
                                  (item.price * item.qty)
                                ).toFixed(org.decimal)
                              }`
                            : t("noTax")}
                        </td>
                        <td style={td_th_cls}>
                          {(org && org.curSymbol) +
                            " " +
                            (item.qty * item.price).toFixed(org.decimal)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        "No Sale"
      )}
    </>
  );
};

export default PrintRegReport;
