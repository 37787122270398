import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, InputCheck } from "../../../controls";
import { Box, Divider, Grid } from "@mui/material";
import DashbaordAccess from "./DashbaordAccess";
import ProductAccess from "./ProductAccess";
import InventoryAccess from "./InventoryAccess";
import PurchaseAccess from "./PurchaseAccess";
import PurchaseReturnAccess from "./PurchaseReturnAccess";
import SupplierAccess from "./SupplierAccess";
import ExpenseCategoryAccess from "./ExpenseCategoryAccess";
import SellsAccess from "./SellsAccess";
import StockTransferAccess from "./StockTransferAccess";
import ReportAccess from "./ReportAccess";
import UserAccess from "./UserAccess";
import PayBillAccess from "./PayBillAccess ";
import SalaryAccess from "./SalaryAccess ";
import CurrencyAccess from "./CurrencyAccess";
import PaymentMethodAccess from "./PaymentMethodAccess";
import PosAccess from "./PosAccess";
import CategoryAccess from "./CategoryAccess";
import CustomerAccess from "./CustomerAccess";
import StoreAccess from "./StoreAccess";

const FeaturesPermissionForm = ({ setShowForm, setValues, values }) => {
  const [featuresAccess, setFeaturesAccess] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setFeaturesAccess((j) => ({ ...j, ...values.featuresAccess }));
    return () => {};
  }, [values]);

  const handleInpChg = (e) => {
    const { name, checked } = e.target;
    name && setFeaturesAccess((j) => ({ ...j, [name]: checked }));
  };

  const handleSubmit = () => {
    setValues((j) => ({ ...j, featuresAccess }));
    setShowForm(false);
  };

  return (
    <>
      {/* ========== Header ========== */}
      <div className="d-flex flex-wrap j-between a-center p-10 g-10">
        <div>
          <span className="border-left fs-20 tx-upp fw-5 gray-color">
            {t("permissions")}
          </span>
        </div>
        <Box className="d-flex g-10" sx={{ width: { xs: "100%", sm: "auto" } }}>
          <Button
            onClick={() => setShowForm(false)}
            className="error-color bg-errorSM"
            sx={{ width: { xs: "100%", sm: "auto" } }}
          >
            {t("close")}
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{ width: { xs: "100%", sm: "auto" } }}
          >
            {t("apply")}
          </Button>
        </Box>
      </div>
      <Divider />
      {/* ========== Features Permission Form ========== */}
      {values ? (
        <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <Grid container spacing={2.5} className="p-20">
            <Grid item xs={12} md={4}>
              <InputCheck
                label={t("canCustomizeDefaultStore")}
                checked={featuresAccess.canCustomizeDefaultStore || false}
                name={"canCustomizeDefaultStore"}
                onClick={handleInpChg}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputCheck
                label={t("canEditOrganizationDetails")}
                checked={featuresAccess.canEditOrganizationDetails || false}
                name={"canEditOrganizationDetails"}
                onClick={handleInpChg}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputCheck
                label={t("canCustomizeDefaultPosItem")}
                checked={featuresAccess.canCustomizeDefaultPosItem || false}
                name={"canCustomizeDefaultPosItem"}
                onClick={handleInpChg}
              />
            </Grid>
            {/* ===== Dashbaord Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveDashboardAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveDashboardAccess || false}
                name={"isHaveDashboardAccess"}
                onClick={handleInpChg}
              />
              <DashbaordAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Report Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveReportAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveReportAccess || false}
                name={"isHaveReportAccess"}
                onClick={handleInpChg}
              />
              <ReportAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>

            {/* ===== Product Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveProductAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveProductAccess || false}
                name={"isHaveProductAccess"}
                onClick={handleInpChg}
              />
              <ProductAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Customer Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveCustomerAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveCustomerAccess || false}
                name={"isHaveCustomerAccess"}
                onClick={handleInpChg}
              />
              <CustomerAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Category Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveCategoryAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveCategoryAccess || false}
                name={"isHaveCategoryAccess"}
                onClick={handleInpChg}
              />
              <CategoryAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Inventory Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveInventoryAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveInventoryAccess || false}
                name={"isHaveInventoryAccess"}
                onClick={handleInpChg}
              />
              <InventoryAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Purchase Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHavePurchaseAccess")}
                  </span>
                }
                checked={featuresAccess.isHavePurchaseAccess || false}
                name={"isHavePurchaseAccess"}
                onClick={handleInpChg}
              />
              <PurchaseAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Purchase Return Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHavePurchaseReturnAccess")}
                  </span>
                }
                checked={featuresAccess.isHavePurchaseReturnAccess || false}
                name={"isHavePurchaseReturnAccess"}
                onClick={handleInpChg}
              />
              <PurchaseReturnAccess
                {...{ setFeaturesAccess, featuresAccess }}
              />
            </Grid>
            {/* ===== Supplier Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveSupplierAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveSupplierAccess || false}
                name={"isHaveSupplierAccess"}
                onClick={handleInpChg}
              />
              <SupplierAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>

            {/* ===== Expense Category Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveExpenseCategoryAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveExpenseCategoryAccess || false}
                name={"isHaveExpenseCategoryAccess"}
                onClick={handleInpChg}
              />
              <ExpenseCategoryAccess
                {...{ setFeaturesAccess, featuresAccess }}
              />
            </Grid>

            {/* ===== Sells Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveSellsAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveSellsAccess || false}
                name={"isHaveSellsAccess"}
                onClick={handleInpChg}
              />
              <SellsAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Stock Transfer Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveStockTransferAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveStockTransferAccess || false}
                name={"isHaveStockTransferAccess"}
                onClick={handleInpChg}
              />
              <StockTransferAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== User Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveUserAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveUserAccess || false}
                name={"isHaveUserAccess"}
                onClick={handleInpChg}
              />
              <UserAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Paybill Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHavePayBillsAccess")}
                  </span>
                }
                checked={featuresAccess.isHavePayBillsAccess || false}
                name={"isHavePayBillsAccess"}
                onClick={handleInpChg}
              />
              <PayBillAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Salary Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveSalaryPaymentAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveSalaryPaymentAccess || false}
                name={"isHaveSalaryPaymentAccess"}
                onClick={handleInpChg}
              />
              <SalaryAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Currency Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveCurrencyAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveCurrencyAccess || false}
                name={"isHaveCurrencyAccess"}
                onClick={handleInpChg}
              />
              <CurrencyAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Payment Method Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHavePaymentMethodAccess")}
                  </span>
                }
                checked={featuresAccess.isHavePaymentMethodAccess || false}
                name={"isHavePaymentMethodAccess"}
                onClick={handleInpChg}
              />
              <PaymentMethodAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== Store Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHaveStoreAccess")}
                  </span>
                }
                checked={featuresAccess.isHaveStoreAccess || false}
                name={"isHaveStoreAccess"}
                onClick={handleInpChg}
              />
              <StoreAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
            {/* ===== POS Access ===== */}
            <Grid item xs={12} md={6}>
              <InputCheck
                label={
                  <span className="fw-5 gray-color fs-18 tx-upp">
                    {t("isHavePosAccess")}
                  </span>
                }
                checked={featuresAccess.isHavePosAccess || false}
                name={"isHavePosAccess"}
                onClick={handleInpChg}
              />
              <PosAccess {...{ setFeaturesAccess, featuresAccess }} />
            </Grid>
          </Grid>
        </div>
      ) : (
        <span className="p-20 fs-18 fw-5">Loading...</span>
      )}
    </>
  );
};

export default FeaturesPermissionForm;
