import { Grid } from "@mui/material";
import React from "react";
import { Input, InputAuto, InputDate, Select } from "../../controls";
import { DropLi } from "../../hooks";
import { MaxLength } from "../../hooks/Method";
import moment from "moment";

const GeneralFields = ({ values, t, handleInpChg, errors, handleInpDate }) => {
  const { Prefix, Languages, Gender } = DropLi();
  return (
    <Grid container spacing={2} className="mb-40 mt-10">
      <Grid item xs={12} className="mb-10">
        <span className="border-left tx-upp fw-5 gray-color pl-10 ml-10">
          {t("userInformation")}
        </span>
      </Grid>
      <Grid item xs={12} md={2}>
        <Select
          label={t("prefix")}
          name="prefix"
          fullWidth
          required
          value={values.prefix}
          error={errors.prefix}
          onChange={handleInpChg}
          options={Prefix}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Input
          required
          label={t("firstName")}
          name="firstName"
          value={values.firstName}
          error={errors.firstName}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 300 }}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Input
          required
          label={t("lastName")}
          name="lastName"
          value={values.lastName}
          error={errors.lastName}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 300 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputDate
          label={t("dateOfBirth")}
          name="dob"
          value={values.dob}
          error={errors.dob}
          onChange={(date) => handleInpDate("dob", date)}
          maxDate={moment().subtract(15, "years")}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          required
          label={t("email")}
          name="email"
          value={values.email}
          error={errors.email}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 400 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputAuto
          required
          label={t("language")}
          name="language"
          value={values.language}
          error={errors.language}
          onChange={handleInpChg}
          options={Languages}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("phone")}
          name="phone"
          className="rmv-arrow"
          value={values.phone}
          error={errors.phone}
          onChange={handleInpChg}
          onInput={(e) => (e.target.value = MaxLength(e.target.value, 15))}
          fullWidth
          inputProps={{ maxLength: 300 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Select
          label={t("gender")}
          name="gender"
          fullWidth
          value={values.gender}
          error={errors.gender}
          onChange={handleInpChg}
          options={Gender}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Input
          label={t("SSN")}
          name="ssn"
          value={values.ssn}
          error={errors.ssn}
          onChange={handleInpChg}
          fullWidth
          inputProps={{ maxLength: 400 }}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralFields;
