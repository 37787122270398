import { Box, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { Input } from "../../controls";
import { useTranslation } from "react-i18next";
import { SearchRounded } from "@mui/icons-material";

const SearchProds = ({ posItems, setFltPosItems }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const handleInpChg = (e) => {
    var val = e.target.value;
    setSearchTerm(val);

    // filter pos list
    setFltPosItems([
      ...posItems.filter(
        (prod) =>
          prod.name.toLowerCase().includes(val) ||
          prod.barcode.toLowerCase().includes(val)
      ),
    ]);
  };

  return (
    <Box className="shadow-xs mb-10" sx={{ borderTopRightRadius: "10px" }}>
      <Input
        label={t("enterNameOrScanBarcode")}
        name="barcode"
        id="searchBox"
        sx={{
          borderTopRightRadius: "10px",
          " .MuiFilledInput-root": {
            backgroundColor: "#fff !important",
          },
        }}
        value={searchTerm}
        onChange={handleInpChg}
        fullWidth
        autoFocus={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchRounded />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchProds;
