import React, { useContext, useState } from "react";
import { Avatar, Divider, IconButton, Tooltip } from "@mui/material";
import { DeleteForeverRounded, EditRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../App";
import { Alert } from "../../../layout";
import { endpoints } from "../../../api/actions/api";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/pos";

const CustomerInfo = ({ cstmr, gender, accBalType, _delete, access }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const handleEdit = () => {
    navigate(`/customers/customer-form/${cstmr.id}`);
  };

  const handleDlt = () => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("cstmrDltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res && res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
            navigate("/customers");

            // setTblData((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: t("error"),
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.Customer, cstmr.id, null, onComplete);
      },
    });
  };

  return (
    <>
      {cstmr ? (
        <div className="d-grid bg-white p-10 br-25">
          <div className="d-flex flex-wrap g-10 j-between">
            {access.isHaveEditAccess && (
              <Tooltip title={t("edit")}>
                <IconButton
                  onClick={handleEdit}
                  size="small"
                  color="primary"
                  sx={{ border: "1px solid var(--primarySM-color)" }}
                >
                  <EditRounded className="fs-20 p-2" />
                </IconButton>
              </Tooltip>
            )}
            {access.isHaveDeleteAccess && (
              <Tooltip title={t("delete")}>
                <IconButton
                  onClick={handleDlt}
                  size="small"
                  color="error"
                  sx={{ border: "1px solid var(--primarySM-color)" }}
                >
                  <DeleteForeverRounded className="fs-20 p-2" />
                </IconButton>
              </Tooltip>
            )}
          </div>

          <div className="d-flex j-center mb-10">
            <Avatar
              src={cstmr.photo}
              alt="profile_pic"
              className="obj-cover"
              sx={{ width: "110px", height: "110px" }}
            />
          </div>
          <div className="d-grid gap-10 j-center">
            <div className="tx-center">
              <p className="fs-28 m-0 mb-5 fw-4">
                {cstmr.firstName + " " + cstmr.lastName}
              </p>
              <p className="fs-12 gray-color m-0">
                {t("customerScince")}:{" "}
                {new Date(cstmr.createDate).toLocaleDateString()}
              </p>
            </div>
          </div>
          <div className="p-10">
            <div className="d-flex flex-wrap a-center j-between mt-40">
              <div className="d-grid">
                <span className="fs-22 fw-5 primary-color">
                  {org &&
                    org.curSymbol + " " + cstmr.creditBal.toFixed(org.decimal)}
                </span>
                <span className="fs-12 primary-color">{t("credit")}</span>
              </div>

              <div className="d-grid">
                <span className="fs-22 fw-5 error-color">
                  {org && org.curSymbol + " " + cstmr.due.toFixed(org.decimal)}
                </span>
                <span className="fs-12 error-color">{t("due")}</span>
              </div>
            </div>
            <div className="d-flex a-center mt-20 mb-10">
              <span
                className="fs-12 fs-italic tx-upp gray-color"
                style={{ width: "180px" }}
              >
                {t("personalDetails")}
              </span>
              <div className="bs-secondary w-100pr" />
            </div>
            {cstmr.dob && (
              <>
                <div className="d-flex flax-wrap j-between a-center mb-5">
                  <span className="fs-15 mr-30">{t("birthday")} :</span>
                  <div className="d-grid tx-center gray-color">
                    <span className="fs-15">
                      {moment().diff(moment(cstmr.dob, "MM/DD/YYYY"), "years")}{" "}
                      {t("yearsOld")}
                    </span>
                    <span className="fs-12 gray-color">
                      {new Date(cstmr.dob).toDateString()}
                    </span>
                  </div>
                </div>
                <Divider />
              </>
            )}

            <div className="d-flex flax-wrap j-between a-center mt-10 mb-10">
              <span className="fs-15 mr-30">{t("gender")} :</span>
              <span className="fs-15 gray-color">{gender}</span>
            </div>

            <Divider />
            <div className="d-flex flax-wrap j-between a-center mt-10 mb-10">
              <span className="fs-15 mr-30">{t("customerCode")}:</span>
              <span className="fs-15 gray-color">{cstmr.cstmrCode}</span>
            </div>
            <Divider />
            <div className="d-flex flax-wrap j-between a-center mt-10 mb-10">
              <span className="fs-15 mr-30">{t("accountBalanceType")} :</span>
              <span className="fs-15 gray-color">{accBalType}</span>
            </div>
            <Divider />
            <div className="d-flex flax-wrap j-between a-center mt-10 mb-10">
              <span className="fs-15 mr-30">{t("phone")} :</span>
              <a className="fs-15 link" href={`tel:${cstmr.phone}`}>
                {cstmr.phone}
              </a>
            </div>
            <Divider />
            <div className="d-flex flax-wrap j-between a-center mt-10 mb-10">
              <span className="fs-15 mr-30">{t("email")} :</span>
              <a className="link fs-15" href={`mailto:${cstmr.email}`}>
                {cstmr.email}
              </a>
            </div>
          </div>
        </div>
      ) : (
        `${"loading"}...`
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(CustomerInfo);
